/*
Template Name: Prompt Kit
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap");
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #05f;
  --bs-indigo: #5c7cfa;
  --bs-purple: #a499ff;
  --bs-pink: #f06595;
  --bs-red: #f0323c;
  --bs-orange: #ff784b;
  --bs-yellow: #ffc837;
  --bs-green: #3cd278;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-white: #fff;
  --bs-gray: #787878;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f4f5f7;
  --bs-gray-200: #f3f4f7;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #cdcdcd;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #787878;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #05f;
  --bs-secondary: #495057;
  --bs-success: #3cd278;
  --bs-info: #17a2b8;
  --bs-warning: #ffc837;
  --bs-danger: #f0323c;
  --bs-light: #f4f5f7;
  --bs-dark: #343a40;
  --bs-orange: #ff784b;
  --bs-teal: #20c997;
  --bs-gray-50: #fefefe;
  --bs-primary-rgb: 0, 85, 255;
  --bs-secondary-rgb: 73, 80, 87;
  --bs-success-rgb: 60, 210, 120;
  --bs-info-rgb: 23, 162, 184;
  --bs-warning-rgb: 255, 200, 55;
  --bs-danger-rgb: 240, 50, 60;
  --bs-light-rgb: 244, 245, 247;
  --bs-dark-rgb: 52, 58, 64;
  --bs-orange-rgb: 255, 120, 75;
  --bs-teal-rgb: 32, 201, 151;
  --bs-gray-50-rgb: 253.95, 254.1, 254.25;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 73, 80, 87;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Be Vietnam Pro", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Be Vietnam Pro, sans-serif;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: #495057;
  background-color: currentColor;
  border: 0;
  opacity: 0.1; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.4; }

h1, .h1 {
  font-size: calc(1.30313rem + 0.6375vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 1.78125rem; } }

h2, .h2 {
  font-size: calc(1.27969rem + 0.35625vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.54688rem; } }

h3, .h3 {
  font-size: calc(1.25625rem + 0.075vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.3125rem; } }

h4, .h4 {
  font-size: 1.07813rem; }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.07812rem; } }

h5, .h5 {
  font-size: 0.9375rem; }

h6, .h6 {
  font-size: 0.84375rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 700; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #495057;
  text-decoration: none; }
  a:hover {
    color: #05f;
    text-decoration: underline; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #f0323c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 1rem 1rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 600; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8a90a2;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.17188rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4rem; } }

.display-3 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 3rem; } }

.display-4 {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 2rem; } }

.display-6 {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #787878; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #787878; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1140px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 7.5rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 7.5rem; }

.g-9,
.gx-9 {
  --bs-gutter-x: 9rem; }

.g-9,
.gy-9 {
  --bs-gutter-y: 9rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 11.5rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 11.5rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 7.5rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 7.5rem; }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 9rem; }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 9rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 11.5rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 11.5rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 7.5rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 7.5rem; }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 9rem; }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 9rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 11.5rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 11.5rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 7.5rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 7.5rem; }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 9rem; }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 9rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 11.5rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 11.5rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 7.5rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 7.5rem; }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 9rem; }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 9rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 11.5rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 11.5rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 7.5rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 7.5rem; }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 9rem; }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 9rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 11.5rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 11.5rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #495057;
  --bs-table-striped-bg: #f3f4f7;
  --bs-table-active-color: #495057;
  --bs-table-active-bg: #f3f4f7;
  --bs-table-hover-color: #495057;
  --bs-table-hover-bg: #f3f4f7;
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid #dee2e6; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 3.75rem 3.75rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #ccddff;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #bbcbea; }

.table-secondary {
  --bs-table-bg: #dbdcdd;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #c8cacb; }

.table-success {
  --bs-table-bg: #d8f6e4;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #c6e1d1; }

.table-info {
  --bs-table-bg: #d1ecf1;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #bfd8dd; }

.table-warning {
  --bs-table-bg: #fff4d7;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #e9dfc6; }

.table-danger {
  --bs-table-bg: #fcd6d8;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #e6c4c7; }

.table-light {
  --bs-table-bg: #f4f5f7;
  --bs-table-striped-bg: #212529;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #212529;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #212529;
  --bs-table-hover-color: #fff;
  color: #212529;
  border-color: #dfe0e2; }

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: white;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: white;
  --bs-table-hover-color: #212529;
  color: #fff;
  border-color: #484e53; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.25rem;
  font-weight: 600; }

.col-form-label {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 600;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  font-size: 1.17188rem; }

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.82031rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #8a90a2; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #787878;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fff;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.5625rem 1rem;
    margin: -0.5625rem -1rem;
    margin-inline-end: 1rem;
    color: #495057;
    background-color: #f3f4f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e7e8eb; }
  .form-control::-webkit-file-upload-button {
    padding: 0.5625rem 1rem;
    margin: -0.5625rem -1rem;
    margin-inline-end: 1rem;
    color: #495057;
    background-color: #f3f4f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e7e8eb; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5625rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.82031rem;
  border-radius: 0.25rem; }
  .form-control-sm::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem; }

.form-control-lg {
  min-height: calc(1.5em + 1.875rem + 2px);
  padding: 0.9375rem 1.25rem;
  font-size: 1.17188rem;
  border-radius: 0.25rem; }
  .form-control-lg::file-selector-button {
    padding: 0.9375rem 1.25rem;
    margin: -0.9375rem -1.25rem;
    margin-inline-end: 1.25rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.9375rem 1.25rem;
    margin: -0.9375rem -1.25rem;
    margin-inline-end: 1.25rem; }

textarea.form-control {
  min-height: calc(1.5em + 1.125rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1.875rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.5625rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.5625rem 3rem 0.5625rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 14px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #f3f4f7; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.82031rem;
  border-radius: 0.25rem; }

.form-select-lg {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-left: 1.25rem;
  font-size: 1.17188rem;
  border-radius: 0.25rem; }

.form-check {
  display: block;
  min-height: 1.40625rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25rem; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }
  .form-check-input:checked {
    background-color: #05f;
    border-color: #05f; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #05f;
    border-color: #05f;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #05f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b3ccff; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #05f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b3ccff; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 1rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 1rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #f3f4f7;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.9375rem 1.25rem;
  font-size: 1.17188rem;
  border-radius: 0.25rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.82031rem;
  border-radius: 0.25rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3cd278; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: rgba(60, 210, 120, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3cd278;
  padding-right: calc(1.5em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233cd278' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.28125rem) center;
  background-size: calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #3cd278;
    box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.125rem);
  background-position: top calc(0.375em + 0.28125rem) right calc(0.375em + 0.28125rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3cd278; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 5.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233cd278' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 3rem;
    background-size: 14px 12px, calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #3cd278;
    box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3cd278; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #3cd278; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #3cd278; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f0323c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.3rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.9375rem;
  color: #fff;
  background-color: rgba(240, 50, 60, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f0323c;
  padding-right: calc(1.5em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f0323c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f0323c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.28125rem) center;
  background-size: calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f0323c;
    box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.125rem);
  background-position: top calc(0.375em + 0.28125rem) right calc(0.375em + 0.28125rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f0323c; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 5.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f0323c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f0323c' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 3rem;
    background-size: 14px 12px, calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #f0323c;
    box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f0323c; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #f0323c; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #f0323c; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #495057;
    text-decoration: none; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #05f;
  border-color: #05f; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0048d9;
    border-color: #0044cc; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #0048d9;
    border-color: #0044cc;
    box-shadow: 0 0 0 0.15rem rgba(38, 111, 255, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0044cc;
    border-color: #0040bf; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(38, 111, 255, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #05f;
    border-color: #05f; }

.btn-secondary {
  color: #fff;
  background-color: #495057;
  border-color: #495057; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #3e444a;
    border-color: #3a4046; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #3e444a;
    border-color: #3a4046;
    box-shadow: 0 0 0 0.15rem rgba(100, 106, 112, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3a4046;
    border-color: #373c41; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(100, 106, 112, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }

.btn-success {
  color: #fff;
  background-color: #3cd278;
  border-color: #3cd278; }
  .btn-success:hover {
    color: #fff;
    background-color: #33b366;
    border-color: #30a860; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #33b366;
    border-color: #30a860;
    box-shadow: 0 0 0 0.15rem rgba(89, 217, 140, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #30a860;
    border-color: #2d9e5a; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(89, 217, 140, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #3cd278;
    border-color: #3cd278; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #148a9c;
    border-color: #128293; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fff;
    background-color: #148a9c;
    border-color: #128293;
    box-shadow: 0 0 0 0.15rem rgba(58, 176, 195, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #128293;
    border-color: #117a8a; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(58, 176, 195, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-warning {
  color: #212529;
  background-color: #ffc837;
  border-color: #ffc837; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffd055;
    border-color: #ffce4b; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #212529;
    background-color: #ffd055;
    border-color: #ffce4b;
    box-shadow: 0 0 0 0.15rem rgba(222, 176, 53, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd35f;
    border-color: #ffce4b; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(222, 176, 53, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #212529;
    background-color: #ffc837;
    border-color: #ffc837; }

.btn-danger {
  color: #fff;
  background-color: #f0323c;
  border-color: #f0323c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #cc2b33;
    border-color: #c02830; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #cc2b33;
    border-color: #c02830;
    box-shadow: 0 0 0 0.15rem rgba(242, 81, 89, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c02830;
    border-color: #b4262d; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(242, 81, 89, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #f0323c;
    border-color: #f0323c; }

.btn-light {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7; }
  .btn-light:hover {
    color: #212529;
    background-color: #f6f7f8;
    border-color: #f5f6f8; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #212529;
    background-color: #f6f7f8;
    border-color: #f5f6f8;
    box-shadow: 0 0 0 0.15rem rgba(212, 214, 216, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #f6f7f9;
    border-color: #f5f6f8; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(212, 214, 216, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #212529;
    background-color: #f4f5f7;
    border-color: #f4f5f7; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2c3136;
    border-color: #2a2e33; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #2c3136;
    border-color: #2a2e33;
    box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2a2e33;
    border-color: #272c30; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-orange {
  color: #fff;
  background-color: #ff784b;
  border-color: #ff784b; }
  .btn-orange:hover {
    color: #fff;
    background-color: #d96640;
    border-color: #cc603c; }
  .btn-check:focus + .btn-orange, .btn-orange:focus {
    color: #fff;
    background-color: #d96640;
    border-color: #cc603c;
    box-shadow: 0 0 0 0.15rem rgba(255, 140, 102, 0.5); }
  .btn-check:checked + .btn-orange,
  .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #cc603c;
    border-color: #bf5a38; }
    .btn-check:checked + .btn-orange:focus,
    .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 140, 102, 0.5); }
  .btn-orange:disabled, .btn-orange.disabled {
    color: #fff;
    background-color: #ff784b;
    border-color: #ff784b; }

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-teal:hover {
    color: #fff;
    background-color: #1bab80;
    border-color: #1aa179; }
  .btn-check:focus + .btn-teal, .btn-teal:focus {
    color: #fff;
    background-color: #1bab80;
    border-color: #1aa179;
    box-shadow: 0 0 0 0.15rem rgba(65, 209, 167, 0.5); }
  .btn-check:checked + .btn-teal,
  .btn-check:active + .btn-teal, .btn-teal:active, .btn-teal.active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #1aa179;
    border-color: #189771; }
    .btn-check:checked + .btn-teal:focus,
    .btn-check:active + .btn-teal:focus, .btn-teal:active:focus, .btn-teal.active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(65, 209, 167, 0.5); }
  .btn-teal:disabled, .btn-teal.disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }

.btn-gray-50 {
  color: #212529;
  background-color: #fefefe;
  border-color: #fefefe; }
  .btn-gray-50:hover {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
  .btn-check:focus + .btn-gray-50, .btn-gray-50:focus {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe;
    box-shadow: 0 0 0 0.15rem rgba(221, 222, 222, 0.5); }
  .btn-check:checked + .btn-gray-50,
  .btn-check:active + .btn-gray-50, .btn-gray-50:active, .btn-gray-50.active,
  .show > .btn-gray-50.dropdown-toggle {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
    .btn-check:checked + .btn-gray-50:focus,
    .btn-check:active + .btn-gray-50:focus, .btn-gray-50:active:focus, .btn-gray-50.active:focus,
    .show > .btn-gray-50.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(221, 222, 222, 0.5); }
  .btn-gray-50:disabled, .btn-gray-50.disabled {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }

.btn-outline-primary {
  color: #05f;
  border-color: #05f; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #05f;
    border-color: #05f; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #05f;
    border-color: #05f; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #05f;
    background-color: transparent; }

.btn-outline-secondary {
  color: #495057;
  border-color: #495057; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.15rem rgba(73, 80, 87, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(73, 80, 87, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #495057;
    background-color: transparent; }

.btn-outline-success {
  color: #3cd278;
  border-color: #3cd278; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3cd278;
    border-color: #3cd278; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #3cd278;
    border-color: #3cd278; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #3cd278;
    background-color: transparent; }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.15rem rgba(23, 162, 184, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #17a2b8;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc837;
  border-color: #ffc837; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc837;
    border-color: #ffc837; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 200, 55, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #212529;
    background-color: #ffc837;
    border-color: #ffc837; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 200, 55, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc837;
    background-color: transparent; }

.btn-outline-danger {
  color: #f0323c;
  border-color: #f0323c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f0323c;
    border-color: #f0323c; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #f0323c;
    border-color: #f0323c; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #f0323c;
    background-color: transparent; }

.btn-outline-light {
  color: #f4f5f7;
  border-color: #f4f5f7; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f4f5f7;
    border-color: #f4f5f7; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(244, 245, 247, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #212529;
    background-color: #f4f5f7;
    border-color: #f4f5f7; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(244, 245, 247, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f4f5f7;
    background-color: transparent; }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #343a40;
    background-color: transparent; }

.btn-outline-orange {
  color: #ff784b;
  border-color: #ff784b; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #ff784b;
    border-color: #ff784b; }
  .btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 120, 75, 0.5); }
  .btn-check:checked + .btn-outline-orange,
  .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
    color: #fff;
    background-color: #ff784b;
    border-color: #ff784b; }
    .btn-check:checked + .btn-outline-orange:focus,
    .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 120, 75, 0.5); }
  .btn-outline-orange:disabled, .btn-outline-orange.disabled {
    color: #ff784b;
    background-color: transparent; }

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-check:focus + .btn-outline-teal, .btn-outline-teal:focus {
    box-shadow: 0 0 0 0.15rem rgba(32, 201, 151, 0.5); }
  .btn-check:checked + .btn-outline-teal,
  .btn-check:active + .btn-outline-teal, .btn-outline-teal:active, .btn-outline-teal.active, .btn-outline-teal.dropdown-toggle.show {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-check:checked + .btn-outline-teal:focus,
    .btn-check:active + .btn-outline-teal:focus, .btn-outline-teal:active:focus, .btn-outline-teal.active:focus, .btn-outline-teal.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(32, 201, 151, 0.5); }
  .btn-outline-teal:disabled, .btn-outline-teal.disabled {
    color: #20c997;
    background-color: transparent; }

.btn-outline-gray-50 {
  color: #fefefe;
  border-color: #fefefe; }
  .btn-outline-gray-50:hover {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
  .btn-check:focus + .btn-outline-gray-50, .btn-outline-gray-50:focus {
    box-shadow: 0 0 0 0.15rem rgba(254, 254, 254, 0.5); }
  .btn-check:checked + .btn-outline-gray-50,
  .btn-check:active + .btn-outline-gray-50, .btn-outline-gray-50:active, .btn-outline-gray-50.active, .btn-outline-gray-50.dropdown-toggle.show {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
    .btn-check:checked + .btn-outline-gray-50:focus,
    .btn-check:active + .btn-outline-gray-50:focus, .btn-outline-gray-50:active:focus, .btn-outline-gray-50.active:focus, .btn-outline-gray-50.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(254, 254, 254, 0.5); }
  .btn-outline-gray-50:disabled, .btn-outline-gray-50.disabled {
    color: #fefefe;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #495057;
  text-decoration: none; }
  .btn-link:hover {
    color: #05f;
    text-decoration: underline; }
  .btn-link:focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #787878; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9375rem 1.25rem;
  font-size: 1.17188rem;
  border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.82031rem;
  border-radius: 0.25rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0.3rem;
  margin: 0;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f3f4f7; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #787878;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    text-decoration: none;
    background-color: #f3f4f7; }
  .dropdown-item.active, .dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: #f4f5f7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: #787878;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.35rem 1.5rem;
  color: #787878; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #16181b;
      background-color: #f4f5f7; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: #f3f4f7; }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #05f;
    text-decoration: none; }
  .nav-link.disabled {
    color: #787878;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0 solid "transparent"; }
  .nav-tabs .nav-link {
    margin-bottom: 0;
    background: none;
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #f3f4f7 #f3f4f7 "transparent";
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #787878;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #05f; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.32422rem;
  padding-bottom: 0.32422rem;
  margin-right: 1rem;
  font-size: 1.17188rem;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.75rem 0;
  font-size: 1.17188rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.15rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: #0f1012; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #0f1012; }

.navbar-light .navbar-nav .nav-link {
  color: #32373b; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #0f1012; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #0f1012; }

.navbar-light .navbar-toggler {
  color: #32373b;
  border-color: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2332373b' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #32373b; }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: #0f1012; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: white; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.1875rem - 0px);
      border-top-right-radius: calc(0.1875rem - 0px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.1875rem - 0px);
      border-bottom-left-radius: calc(0.1875rem - 0px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #f3f4f7;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.1875rem - 0px) calc(0.1875rem - 0px) 0 0; }

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #f3f4f7;
  border-top: 0px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.1875rem - 0px) calc(0.1875rem - 0px); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.1875rem - 0px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.1875rem - 0px);
  border-top-right-radius: calc(0.1875rem - 0px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.1875rem - 0px);
  border-bottom-left-radius: calc(0.1875rem - 0px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #004de6;
    background-color: #e6eeff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23004de6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23495057'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.375rem - 1px);
      border-bottom-left-radius: calc(0.375rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f4f5f7; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.1rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.1rem;
    color: #787878;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #787878; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #05f;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #05f;
    text-decoration: none;
    background-color: #f3f4f7;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #05f;
    background-color: #f3f4f7;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #05f;
  border-color: #05f; }

.page-item.disabled .page-link {
  color: #787878;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.badge {
  display: inline-block;
  padding: 0.3em 0.5em;
  font-size: 82%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 3.75rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.9375rem 1.25rem; }

.alert-primary {
  color: #003399;
  background-color: #ccddff;
  border-color: #b3ccff; }
  .alert-primary .alert-link {
    color: #00297a; }

.alert-secondary {
  color: #2c3034;
  background-color: #dbdcdd;
  border-color: #c8cbcd; }
  .alert-secondary .alert-link {
    color: #23262a; }

.alert-success {
  color: #247e48;
  background-color: #d8f6e4;
  border-color: #c5f2d7; }
  .alert-success .alert-link {
    color: #1d653a; }

.alert-info {
  color: #0e616e;
  background-color: #d1ecf1;
  border-color: #b9e3ea; }
  .alert-info .alert-link {
    color: #0b4e58; }

.alert-warning {
  color: #997821;
  background-color: #fff4d7;
  border-color: #ffefc3; }
  .alert-warning .alert-link {
    color: #7a601a; }

.alert-danger {
  color: #901e24;
  background-color: #fcd6d8;
  border-color: #fbc2c5; }
  .alert-danger .alert-link {
    color: #73181d; }

.alert-light {
  color: #929394;
  background-color: #fdfdfd;
  border-color: #fcfcfd; }
  .alert-light .alert-link {
    color: #757676; }

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6; }
  .alert-dark .alert-link {
    color: #191c1e; }

.alert-orange {
  color: #99482d;
  background-color: #ffe4db;
  border-color: #ffd7c9; }
  .alert-orange .alert-link {
    color: #7a3a24; }

.alert-teal {
  color: #13795b;
  background-color: #d2f4ea;
  border-color: #bcefe0; }
  .alert-teal .alert-link {
    color: #0f6149; }

.alert-gray-50 {
  color: #989899;
  background-color: white;
  border-color: white; }
  .alert-gray-50 .alert-link {
    color: #7a7a7a; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.75rem; } }

.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  font-size: 0.70313rem;
  background-color: #f3f4f7;
  border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .progress {
      font-size: 0.70312rem; } }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #05f;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f4f5f7; }
  .list-group-item-action:active {
    color: #495057;
    background-color: #f3f4f7; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #787878;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #05f;
    border-color: #05f; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #003399;
  background-color: #ccddff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003399;
    background-color: #b8c7e6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003399;
    border-color: #003399; }

.list-group-item-secondary {
  color: #2c3034;
  background-color: #dbdcdd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2c3034;
    background-color: #c5c6c7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2c3034;
    border-color: #2c3034; }

.list-group-item-success {
  color: #247e48;
  background-color: #d8f6e4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #247e48;
    background-color: #c2ddcd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #247e48;
    border-color: #247e48; }

.list-group-item-info {
  color: #0e616e;
  background-color: #d1ecf1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0e616e;
    background-color: #bcd4d9; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0e616e;
    border-color: #0e616e; }

.list-group-item-warning {
  color: #997821;
  background-color: #fff4d7; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #997821;
    background-color: #e6dcc2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #997821;
    border-color: #997821; }

.list-group-item-danger {
  color: #901e24;
  background-color: #fcd6d8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #901e24;
    background-color: #e3c1c2; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #901e24;
    border-color: #901e24; }

.list-group-item-light {
  color: #929394;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #929394;
    background-color: #e4e4e4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #929394;
    border-color: #929394; }

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f2326;
    background-color: #c1c2c3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2326;
    border-color: #1f2326; }

.list-group-item-orange {
  color: #99482d;
  background-color: #ffe4db; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #99482d;
    background-color: #e6cdc5; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #99482d;
    border-color: #99482d; }

.list-group-item-teal {
  color: #13795b;
  background-color: #d2f4ea; }
  .list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #13795b;
    background-color: #bddcd3; }
  .list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #13795b;
    border-color: #13795b; }

.list-group-item-gray-50 {
  color: #989899;
  background-color: white; }
  .list-group-item-gray-50.list-group-item-action:hover, .list-group-item-gray-50.list-group-item-action:focus {
    color: #989899;
    background-color: #e6e6e6; }
  .list-group-item-gray-50.list-group-item-action.active {
    color: #fff;
    background-color: #989899;
    border-color: #989899; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06);
  border-radius: 0.375rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #787878;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.75rem;
    height: 0.5rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.5rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.5rem 0.375rem 0;
      border-top-color: #1a1d20; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.5rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.5rem;
    height: 0.75rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.375rem 0.5rem 0.375rem 0;
      border-right-color: #1a1d20; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.5rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.375rem 0.5rem;
      border-bottom-color: #1a1d20; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.5rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.5rem;
    height: 0.75rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.375rem 0 0.375rem 0.5rem;
      border-left-color: #1a1d20; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.3rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #1a1d20;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f3f4f7;
  border-radius: 0.25rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #f3f4f7; }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #f3f4f7; }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #f3f4f7; }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f4f5f7; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #f3f4f7; }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  background-color: #f4f5f7;
  border-bottom: 1px solid #f3f4f7;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #495057; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #05f; }
  .link-primary:hover, .link-primary:focus {
    color: #003cb3; }

.link-secondary {
  color: #495057; }
  .link-secondary:hover, .link-secondary:focus {
    color: #33383d; }

.link-success {
  color: #3cd278; }
  .link-success:hover, .link-success:focus {
    color: #2a9354; }

.link-info {
  color: #17a2b8; }
  .link-info:hover, .link-info:focus {
    color: #107181; }

.link-warning {
  color: #ffc837; }
  .link-warning:hover, .link-warning:focus {
    color: #ffd973; }

.link-danger {
  color: #f0323c; }
  .link-danger:hover, .link-danger:focus {
    color: #a8232a; }

.link-light {
  color: #f4f5f7; }
  .link-light:hover, .link-light:focus {
    color: #f7f8f9; }

.link-dark {
  color: #343a40; }
  .link-dark:hover, .link-dark:focus {
    color: #24292d; }

.link-orange {
  color: #ff784b; }
  .link-orange:hover, .link-orange:focus {
    color: #b35435; }

.link-teal {
  color: #20c997; }
  .link-teal:hover, .link-teal:focus {
    color: #168d6a; }

.link-gray-50 {
  color: #fefefe; }
  .link-gray-50:hover, .link-gray-50:focus {
    color: #fefefe; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.1; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06) !important; }

.shadow-sm {
  box-shadow: 0 0 0.25rem rgba(73, 80, 87, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #05f !important; }

.border-secondary {
  border-color: #495057 !important; }

.border-success {
  border-color: #3cd278 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc837 !important; }

.border-danger {
  border-color: #f0323c !important; }

.border-light {
  border-color: #f4f5f7 !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-orange {
  border-color: #ff784b !important; }

.border-teal {
  border-color: #20c997 !important; }

.border-gray-50 {
  border-color: #fefefe !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.gap-6 {
  gap: 4.5rem !important; }

.gap-7 {
  gap: 6rem !important; }

.gap-8 {
  gap: 7.5rem !important; }

.gap-9 {
  gap: 9rem !important; }

.gap-10 {
  gap: 11.5rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.m-8 {
  margin: 7.5rem !important; }

.m-9 {
  margin: 9rem !important; }

.m-10 {
  margin: 11.5rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important; }

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-10 {
  margin-right: 11.5rem !important;
  margin-left: 11.5rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important; }

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-10 {
  margin-top: 11.5rem !important;
  margin-bottom: 11.5rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-6 {
  margin-top: 4.5rem !important; }

.mt-7 {
  margin-top: 6rem !important; }

.mt-8 {
  margin-top: 7.5rem !important; }

.mt-9 {
  margin-top: 9rem !important; }

.mt-10 {
  margin-top: 11.5rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-6 {
  margin-right: 4.5rem !important; }

.me-7 {
  margin-right: 6rem !important; }

.me-8 {
  margin-right: 7.5rem !important; }

.me-9 {
  margin-right: 9rem !important; }

.me-10 {
  margin-right: 11.5rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-6 {
  margin-bottom: 4.5rem !important; }

.mb-7 {
  margin-bottom: 6rem !important; }

.mb-8 {
  margin-bottom: 7.5rem !important; }

.mb-9 {
  margin-bottom: 9rem !important; }

.mb-10 {
  margin-bottom: 11.5rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-6 {
  margin-left: 4.5rem !important; }

.ms-7 {
  margin-left: 6rem !important; }

.ms-8 {
  margin-left: 7.5rem !important; }

.ms-9 {
  margin-left: 9rem !important; }

.ms-10 {
  margin-left: 11.5rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.m-n6 {
  margin: -4.5rem !important; }

.m-n7 {
  margin: -6rem !important; }

.m-n8 {
  margin: -7.5rem !important; }

.m-n9 {
  margin: -9rem !important; }

.m-n10 {
  margin: -11.5rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important; }

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important; }

.mx-n8 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important; }

.mx-n9 {
  margin-right: -9rem !important;
  margin-left: -9rem !important; }

.mx-n10 {
  margin-right: -11.5rem !important;
  margin-left: -11.5rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important; }

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.my-n8 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important; }

.my-n9 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important; }

.my-n10 {
  margin-top: -11.5rem !important;
  margin-bottom: -11.5rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mt-n6 {
  margin-top: -4.5rem !important; }

.mt-n7 {
  margin-top: -6rem !important; }

.mt-n8 {
  margin-top: -7.5rem !important; }

.mt-n9 {
  margin-top: -9rem !important; }

.mt-n10 {
  margin-top: -11.5rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -3rem !important; }

.me-n6 {
  margin-right: -4.5rem !important; }

.me-n7 {
  margin-right: -6rem !important; }

.me-n8 {
  margin-right: -7.5rem !important; }

.me-n9 {
  margin-right: -9rem !important; }

.me-n10 {
  margin-right: -11.5rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.mb-n6 {
  margin-bottom: -4.5rem !important; }

.mb-n7 {
  margin-bottom: -6rem !important; }

.mb-n8 {
  margin-bottom: -7.5rem !important; }

.mb-n9 {
  margin-bottom: -9rem !important; }

.mb-n10 {
  margin-bottom: -11.5rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -3rem !important; }

.ms-n6 {
  margin-left: -4.5rem !important; }

.ms-n7 {
  margin-left: -6rem !important; }

.ms-n8 {
  margin-left: -7.5rem !important; }

.ms-n9 {
  margin-left: -9rem !important; }

.ms-n10 {
  margin-left: -11.5rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.p-8 {
  padding: 7.5rem !important; }

.p-9 {
  padding: 9rem !important; }

.p-10 {
  padding: 11.5rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important; }

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.px-10 {
  padding-right: 11.5rem !important;
  padding-left: 11.5rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; }

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.py-10 {
  padding-top: 11.5rem !important;
  padding-bottom: 11.5rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pt-6 {
  padding-top: 4.5rem !important; }

.pt-7 {
  padding-top: 6rem !important; }

.pt-8 {
  padding-top: 7.5rem !important; }

.pt-9 {
  padding-top: 9rem !important; }

.pt-10 {
  padding-top: 11.5rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pe-6 {
  padding-right: 4.5rem !important; }

.pe-7 {
  padding-right: 6rem !important; }

.pe-8 {
  padding-right: 7.5rem !important; }

.pe-9 {
  padding-right: 9rem !important; }

.pe-10 {
  padding-right: 11.5rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pb-6 {
  padding-bottom: 4.5rem !important; }

.pb-7 {
  padding-bottom: 6rem !important; }

.pb-8 {
  padding-bottom: 7.5rem !important; }

.pb-9 {
  padding-bottom: 9rem !important; }

.pb-10 {
  padding-bottom: 11.5rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.ps-6 {
  padding-left: 4.5rem !important; }

.ps-7 {
  padding-left: 6rem !important; }

.ps-8 {
  padding-left: 7.5rem !important; }

.ps-9 {
  padding-left: 9rem !important; }

.ps-10 {
  padding-left: 11.5rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.30313rem + 0.6375vw) !important; }

.fs-2 {
  font-size: calc(1.27969rem + 0.35625vw) !important; }

.fs-3 {
  font-size: calc(1.25625rem + 0.075vw) !important; }

.fs-4 {
  font-size: 1.07813rem !important; }

.fs-5 {
  font-size: 0.9375rem !important; }

.fs-6 {
  font-size: 0.84375rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: 700 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important; }

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important; }

.text-gray-50 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-50-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #8a90a2 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important; }

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-50 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-50-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.25rem !important; }

.rounded-2 {
  border-radius: 0.375rem !important; }

.rounded-3 {
  border-radius: 0.5rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .gap-sm-6 {
    gap: 4.5rem !important; }
  .gap-sm-7 {
    gap: 6rem !important; }
  .gap-sm-8 {
    gap: 7.5rem !important; }
  .gap-sm-9 {
    gap: 9rem !important; }
  .gap-sm-10 {
    gap: 11.5rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-6 {
    margin: 4.5rem !important; }
  .m-sm-7 {
    margin: 6rem !important; }
  .m-sm-8 {
    margin: 7.5rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .m-sm-10 {
    margin: 11.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-10 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-10 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-6 {
    margin-top: 4.5rem !important; }
  .mt-sm-7 {
    margin-top: 6rem !important; }
  .mt-sm-8 {
    margin-top: 7.5rem !important; }
  .mt-sm-9 {
    margin-top: 9rem !important; }
  .mt-sm-10 {
    margin-top: 11.5rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-6 {
    margin-right: 4.5rem !important; }
  .me-sm-7 {
    margin-right: 6rem !important; }
  .me-sm-8 {
    margin-right: 7.5rem !important; }
  .me-sm-9 {
    margin-right: 9rem !important; }
  .me-sm-10 {
    margin-right: 11.5rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-7 {
    margin-bottom: 6rem !important; }
  .mb-sm-8 {
    margin-bottom: 7.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 9rem !important; }
  .mb-sm-10 {
    margin-bottom: 11.5rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-6 {
    margin-left: 4.5rem !important; }
  .ms-sm-7 {
    margin-left: 6rem !important; }
  .ms-sm-8 {
    margin-left: 7.5rem !important; }
  .ms-sm-9 {
    margin-left: 9rem !important; }
  .ms-sm-10 {
    margin-left: 11.5rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .m-sm-n6 {
    margin: -4.5rem !important; }
  .m-sm-n7 {
    margin: -6rem !important; }
  .m-sm-n8 {
    margin: -7.5rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .m-sm-n10 {
    margin: -11.5rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-sm-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .mx-sm-n10 {
    margin-right: -11.5rem !important;
    margin-left: -11.5rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-sm-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-sm-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .my-sm-n10 {
    margin-top: -11.5rem !important;
    margin-bottom: -11.5rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mt-sm-n6 {
    margin-top: -4.5rem !important; }
  .mt-sm-n7 {
    margin-top: -6rem !important; }
  .mt-sm-n8 {
    margin-top: -7.5rem !important; }
  .mt-sm-n9 {
    margin-top: -9rem !important; }
  .mt-sm-n10 {
    margin-top: -11.5rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -3rem !important; }
  .me-sm-n6 {
    margin-right: -4.5rem !important; }
  .me-sm-n7 {
    margin-right: -6rem !important; }
  .me-sm-n8 {
    margin-right: -7.5rem !important; }
  .me-sm-n9 {
    margin-right: -9rem !important; }
  .me-sm-n10 {
    margin-right: -11.5rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-sm-n7 {
    margin-bottom: -6rem !important; }
  .mb-sm-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -9rem !important; }
  .mb-sm-n10 {
    margin-bottom: -11.5rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -3rem !important; }
  .ms-sm-n6 {
    margin-left: -4.5rem !important; }
  .ms-sm-n7 {
    margin-left: -6rem !important; }
  .ms-sm-n8 {
    margin-left: -7.5rem !important; }
  .ms-sm-n9 {
    margin-left: -9rem !important; }
  .ms-sm-n10 {
    margin-left: -11.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .p-sm-6 {
    padding: 4.5rem !important; }
  .p-sm-7 {
    padding: 6rem !important; }
  .p-sm-8 {
    padding: 7.5rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .p-sm-10 {
    padding: 11.5rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-sm-10 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-sm-10 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pt-sm-6 {
    padding-top: 4.5rem !important; }
  .pt-sm-7 {
    padding-top: 6rem !important; }
  .pt-sm-8 {
    padding-top: 7.5rem !important; }
  .pt-sm-9 {
    padding-top: 9rem !important; }
  .pt-sm-10 {
    padding-top: 11.5rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pe-sm-6 {
    padding-right: 4.5rem !important; }
  .pe-sm-7 {
    padding-right: 6rem !important; }
  .pe-sm-8 {
    padding-right: 7.5rem !important; }
  .pe-sm-9 {
    padding-right: 9rem !important; }
  .pe-sm-10 {
    padding-right: 11.5rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important; }
  .pb-sm-7 {
    padding-bottom: 6rem !important; }
  .pb-sm-8 {
    padding-bottom: 7.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 9rem !important; }
  .pb-sm-10 {
    padding-bottom: 11.5rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .ps-sm-6 {
    padding-left: 4.5rem !important; }
  .ps-sm-7 {
    padding-left: 6rem !important; }
  .ps-sm-8 {
    padding-left: 7.5rem !important; }
  .ps-sm-9 {
    padding-left: 9rem !important; }
  .ps-sm-10 {
    padding-left: 11.5rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .gap-md-6 {
    gap: 4.5rem !important; }
  .gap-md-7 {
    gap: 6rem !important; }
  .gap-md-8 {
    gap: 7.5rem !important; }
  .gap-md-9 {
    gap: 9rem !important; }
  .gap-md-10 {
    gap: 11.5rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-6 {
    margin: 4.5rem !important; }
  .m-md-7 {
    margin: 6rem !important; }
  .m-md-8 {
    margin: 7.5rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .m-md-10 {
    margin: 11.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-10 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-10 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-6 {
    margin-top: 4.5rem !important; }
  .mt-md-7 {
    margin-top: 6rem !important; }
  .mt-md-8 {
    margin-top: 7.5rem !important; }
  .mt-md-9 {
    margin-top: 9rem !important; }
  .mt-md-10 {
    margin-top: 11.5rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-6 {
    margin-right: 4.5rem !important; }
  .me-md-7 {
    margin-right: 6rem !important; }
  .me-md-8 {
    margin-right: 7.5rem !important; }
  .me-md-9 {
    margin-right: 9rem !important; }
  .me-md-10 {
    margin-right: 11.5rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-6 {
    margin-bottom: 4.5rem !important; }
  .mb-md-7 {
    margin-bottom: 6rem !important; }
  .mb-md-8 {
    margin-bottom: 7.5rem !important; }
  .mb-md-9 {
    margin-bottom: 9rem !important; }
  .mb-md-10 {
    margin-bottom: 11.5rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-6 {
    margin-left: 4.5rem !important; }
  .ms-md-7 {
    margin-left: 6rem !important; }
  .ms-md-8 {
    margin-left: 7.5rem !important; }
  .ms-md-9 {
    margin-left: 9rem !important; }
  .ms-md-10 {
    margin-left: 11.5rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .m-md-n6 {
    margin: -4.5rem !important; }
  .m-md-n7 {
    margin: -6rem !important; }
  .m-md-n8 {
    margin: -7.5rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .m-md-n10 {
    margin: -11.5rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-md-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-md-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .mx-md-n10 {
    margin-right: -11.5rem !important;
    margin-left: -11.5rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-md-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-md-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .my-md-n10 {
    margin-top: -11.5rem !important;
    margin-bottom: -11.5rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mt-md-n6 {
    margin-top: -4.5rem !important; }
  .mt-md-n7 {
    margin-top: -6rem !important; }
  .mt-md-n8 {
    margin-top: -7.5rem !important; }
  .mt-md-n9 {
    margin-top: -9rem !important; }
  .mt-md-n10 {
    margin-top: -11.5rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -3rem !important; }
  .me-md-n6 {
    margin-right: -4.5rem !important; }
  .me-md-n7 {
    margin-right: -6rem !important; }
  .me-md-n8 {
    margin-right: -7.5rem !important; }
  .me-md-n9 {
    margin-right: -9rem !important; }
  .me-md-n10 {
    margin-right: -11.5rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-md-n7 {
    margin-bottom: -6rem !important; }
  .mb-md-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -9rem !important; }
  .mb-md-n10 {
    margin-bottom: -11.5rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -3rem !important; }
  .ms-md-n6 {
    margin-left: -4.5rem !important; }
  .ms-md-n7 {
    margin-left: -6rem !important; }
  .ms-md-n8 {
    margin-left: -7.5rem !important; }
  .ms-md-n9 {
    margin-left: -9rem !important; }
  .ms-md-n10 {
    margin-left: -11.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .p-md-6 {
    padding: 4.5rem !important; }
  .p-md-7 {
    padding: 6rem !important; }
  .p-md-8 {
    padding: 7.5rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .p-md-10 {
    padding: 11.5rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-md-10 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-md-10 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pt-md-6 {
    padding-top: 4.5rem !important; }
  .pt-md-7 {
    padding-top: 6rem !important; }
  .pt-md-8 {
    padding-top: 7.5rem !important; }
  .pt-md-9 {
    padding-top: 9rem !important; }
  .pt-md-10 {
    padding-top: 11.5rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pe-md-6 {
    padding-right: 4.5rem !important; }
  .pe-md-7 {
    padding-right: 6rem !important; }
  .pe-md-8 {
    padding-right: 7.5rem !important; }
  .pe-md-9 {
    padding-right: 9rem !important; }
  .pe-md-10 {
    padding-right: 11.5rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pb-md-6 {
    padding-bottom: 4.5rem !important; }
  .pb-md-7 {
    padding-bottom: 6rem !important; }
  .pb-md-8 {
    padding-bottom: 7.5rem !important; }
  .pb-md-9 {
    padding-bottom: 9rem !important; }
  .pb-md-10 {
    padding-bottom: 11.5rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .ps-md-6 {
    padding-left: 4.5rem !important; }
  .ps-md-7 {
    padding-left: 6rem !important; }
  .ps-md-8 {
    padding-left: 7.5rem !important; }
  .ps-md-9 {
    padding-left: 9rem !important; }
  .ps-md-10 {
    padding-left: 11.5rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .gap-lg-6 {
    gap: 4.5rem !important; }
  .gap-lg-7 {
    gap: 6rem !important; }
  .gap-lg-8 {
    gap: 7.5rem !important; }
  .gap-lg-9 {
    gap: 9rem !important; }
  .gap-lg-10 {
    gap: 11.5rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-6 {
    margin: 4.5rem !important; }
  .m-lg-7 {
    margin: 6rem !important; }
  .m-lg-8 {
    margin: 7.5rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .m-lg-10 {
    margin: 11.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-10 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-10 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-6 {
    margin-top: 4.5rem !important; }
  .mt-lg-7 {
    margin-top: 6rem !important; }
  .mt-lg-8 {
    margin-top: 7.5rem !important; }
  .mt-lg-9 {
    margin-top: 9rem !important; }
  .mt-lg-10 {
    margin-top: 11.5rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-6 {
    margin-right: 4.5rem !important; }
  .me-lg-7 {
    margin-right: 6rem !important; }
  .me-lg-8 {
    margin-right: 7.5rem !important; }
  .me-lg-9 {
    margin-right: 9rem !important; }
  .me-lg-10 {
    margin-right: 11.5rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-7 {
    margin-bottom: 6rem !important; }
  .mb-lg-8 {
    margin-bottom: 7.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 9rem !important; }
  .mb-lg-10 {
    margin-bottom: 11.5rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-6 {
    margin-left: 4.5rem !important; }
  .ms-lg-7 {
    margin-left: 6rem !important; }
  .ms-lg-8 {
    margin-left: 7.5rem !important; }
  .ms-lg-9 {
    margin-left: 9rem !important; }
  .ms-lg-10 {
    margin-left: 11.5rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .m-lg-n6 {
    margin: -4.5rem !important; }
  .m-lg-n7 {
    margin: -6rem !important; }
  .m-lg-n8 {
    margin: -7.5rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .m-lg-n10 {
    margin: -11.5rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-lg-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .mx-lg-n10 {
    margin-right: -11.5rem !important;
    margin-left: -11.5rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-lg-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-lg-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .my-lg-n10 {
    margin-top: -11.5rem !important;
    margin-bottom: -11.5rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mt-lg-n6 {
    margin-top: -4.5rem !important; }
  .mt-lg-n7 {
    margin-top: -6rem !important; }
  .mt-lg-n8 {
    margin-top: -7.5rem !important; }
  .mt-lg-n9 {
    margin-top: -9rem !important; }
  .mt-lg-n10 {
    margin-top: -11.5rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -3rem !important; }
  .me-lg-n6 {
    margin-right: -4.5rem !important; }
  .me-lg-n7 {
    margin-right: -6rem !important; }
  .me-lg-n8 {
    margin-right: -7.5rem !important; }
  .me-lg-n9 {
    margin-right: -9rem !important; }
  .me-lg-n10 {
    margin-right: -11.5rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-lg-n7 {
    margin-bottom: -6rem !important; }
  .mb-lg-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -9rem !important; }
  .mb-lg-n10 {
    margin-bottom: -11.5rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -3rem !important; }
  .ms-lg-n6 {
    margin-left: -4.5rem !important; }
  .ms-lg-n7 {
    margin-left: -6rem !important; }
  .ms-lg-n8 {
    margin-left: -7.5rem !important; }
  .ms-lg-n9 {
    margin-left: -9rem !important; }
  .ms-lg-n10 {
    margin-left: -11.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .p-lg-6 {
    padding: 4.5rem !important; }
  .p-lg-7 {
    padding: 6rem !important; }
  .p-lg-8 {
    padding: 7.5rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .p-lg-10 {
    padding: 11.5rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-lg-10 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-lg-10 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pt-lg-6 {
    padding-top: 4.5rem !important; }
  .pt-lg-7 {
    padding-top: 6rem !important; }
  .pt-lg-8 {
    padding-top: 7.5rem !important; }
  .pt-lg-9 {
    padding-top: 9rem !important; }
  .pt-lg-10 {
    padding-top: 11.5rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pe-lg-6 {
    padding-right: 4.5rem !important; }
  .pe-lg-7 {
    padding-right: 6rem !important; }
  .pe-lg-8 {
    padding-right: 7.5rem !important; }
  .pe-lg-9 {
    padding-right: 9rem !important; }
  .pe-lg-10 {
    padding-right: 11.5rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important; }
  .pb-lg-7 {
    padding-bottom: 6rem !important; }
  .pb-lg-8 {
    padding-bottom: 7.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 9rem !important; }
  .pb-lg-10 {
    padding-bottom: 11.5rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .ps-lg-6 {
    padding-left: 4.5rem !important; }
  .ps-lg-7 {
    padding-left: 6rem !important; }
  .ps-lg-8 {
    padding-left: 7.5rem !important; }
  .ps-lg-9 {
    padding-left: 9rem !important; }
  .ps-lg-10 {
    padding-left: 11.5rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .gap-xl-6 {
    gap: 4.5rem !important; }
  .gap-xl-7 {
    gap: 6rem !important; }
  .gap-xl-8 {
    gap: 7.5rem !important; }
  .gap-xl-9 {
    gap: 9rem !important; }
  .gap-xl-10 {
    gap: 11.5rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-6 {
    margin: 4.5rem !important; }
  .m-xl-7 {
    margin: 6rem !important; }
  .m-xl-8 {
    margin: 7.5rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .m-xl-10 {
    margin: 11.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-10 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-10 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-6 {
    margin-top: 4.5rem !important; }
  .mt-xl-7 {
    margin-top: 6rem !important; }
  .mt-xl-8 {
    margin-top: 7.5rem !important; }
  .mt-xl-9 {
    margin-top: 9rem !important; }
  .mt-xl-10 {
    margin-top: 11.5rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-6 {
    margin-right: 4.5rem !important; }
  .me-xl-7 {
    margin-right: 6rem !important; }
  .me-xl-8 {
    margin-right: 7.5rem !important; }
  .me-xl-9 {
    margin-right: 9rem !important; }
  .me-xl-10 {
    margin-right: 11.5rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-7 {
    margin-bottom: 6rem !important; }
  .mb-xl-8 {
    margin-bottom: 7.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 9rem !important; }
  .mb-xl-10 {
    margin-bottom: 11.5rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-6 {
    margin-left: 4.5rem !important; }
  .ms-xl-7 {
    margin-left: 6rem !important; }
  .ms-xl-8 {
    margin-left: 7.5rem !important; }
  .ms-xl-9 {
    margin-left: 9rem !important; }
  .ms-xl-10 {
    margin-left: 11.5rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .m-xl-n6 {
    margin: -4.5rem !important; }
  .m-xl-n7 {
    margin: -6rem !important; }
  .m-xl-n8 {
    margin: -7.5rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .m-xl-n10 {
    margin: -11.5rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .mx-xl-n10 {
    margin-right: -11.5rem !important;
    margin-left: -11.5rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .my-xl-n10 {
    margin-top: -11.5rem !important;
    margin-bottom: -11.5rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mt-xl-n6 {
    margin-top: -4.5rem !important; }
  .mt-xl-n7 {
    margin-top: -6rem !important; }
  .mt-xl-n8 {
    margin-top: -7.5rem !important; }
  .mt-xl-n9 {
    margin-top: -9rem !important; }
  .mt-xl-n10 {
    margin-top: -11.5rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -3rem !important; }
  .me-xl-n6 {
    margin-right: -4.5rem !important; }
  .me-xl-n7 {
    margin-right: -6rem !important; }
  .me-xl-n8 {
    margin-right: -7.5rem !important; }
  .me-xl-n9 {
    margin-right: -9rem !important; }
  .me-xl-n10 {
    margin-right: -11.5rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-xl-n7 {
    margin-bottom: -6rem !important; }
  .mb-xl-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -9rem !important; }
  .mb-xl-n10 {
    margin-bottom: -11.5rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -3rem !important; }
  .ms-xl-n6 {
    margin-left: -4.5rem !important; }
  .ms-xl-n7 {
    margin-left: -6rem !important; }
  .ms-xl-n8 {
    margin-left: -7.5rem !important; }
  .ms-xl-n9 {
    margin-left: -9rem !important; }
  .ms-xl-n10 {
    margin-left: -11.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .p-xl-6 {
    padding: 4.5rem !important; }
  .p-xl-7 {
    padding: 6rem !important; }
  .p-xl-8 {
    padding: 7.5rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .p-xl-10 {
    padding: 11.5rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xl-10 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xl-10 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pt-xl-6 {
    padding-top: 4.5rem !important; }
  .pt-xl-7 {
    padding-top: 6rem !important; }
  .pt-xl-8 {
    padding-top: 7.5rem !important; }
  .pt-xl-9 {
    padding-top: 9rem !important; }
  .pt-xl-10 {
    padding-top: 11.5rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pe-xl-6 {
    padding-right: 4.5rem !important; }
  .pe-xl-7 {
    padding-right: 6rem !important; }
  .pe-xl-8 {
    padding-right: 7.5rem !important; }
  .pe-xl-9 {
    padding-right: 9rem !important; }
  .pe-xl-10 {
    padding-right: 11.5rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important; }
  .pb-xl-7 {
    padding-bottom: 6rem !important; }
  .pb-xl-8 {
    padding-bottom: 7.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 9rem !important; }
  .pb-xl-10 {
    padding-bottom: 11.5rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .ps-xl-6 {
    padding-left: 4.5rem !important; }
  .ps-xl-7 {
    padding-left: 6rem !important; }
  .ps-xl-8 {
    padding-left: 7.5rem !important; }
  .ps-xl-9 {
    padding-left: 9rem !important; }
  .ps-xl-10 {
    padding-left: 11.5rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .gap-xxl-6 {
    gap: 4.5rem !important; }
  .gap-xxl-7 {
    gap: 6rem !important; }
  .gap-xxl-8 {
    gap: 7.5rem !important; }
  .gap-xxl-9 {
    gap: 9rem !important; }
  .gap-xxl-10 {
    gap: 11.5rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-6 {
    margin: 4.5rem !important; }
  .m-xxl-7 {
    margin: 6rem !important; }
  .m-xxl-8 {
    margin: 7.5rem !important; }
  .m-xxl-9 {
    margin: 9rem !important; }
  .m-xxl-10 {
    margin: 11.5rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xxl-10 {
    margin-right: 11.5rem !important;
    margin-left: 11.5rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xxl-10 {
    margin-top: 11.5rem !important;
    margin-bottom: 11.5rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-6 {
    margin-top: 4.5rem !important; }
  .mt-xxl-7 {
    margin-top: 6rem !important; }
  .mt-xxl-8 {
    margin-top: 7.5rem !important; }
  .mt-xxl-9 {
    margin-top: 9rem !important; }
  .mt-xxl-10 {
    margin-top: 11.5rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-6 {
    margin-right: 4.5rem !important; }
  .me-xxl-7 {
    margin-right: 6rem !important; }
  .me-xxl-8 {
    margin-right: 7.5rem !important; }
  .me-xxl-9 {
    margin-right: 9rem !important; }
  .me-xxl-10 {
    margin-right: 11.5rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-7 {
    margin-bottom: 6rem !important; }
  .mb-xxl-8 {
    margin-bottom: 7.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 9rem !important; }
  .mb-xxl-10 {
    margin-bottom: 11.5rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-6 {
    margin-left: 4.5rem !important; }
  .ms-xxl-7 {
    margin-left: 6rem !important; }
  .ms-xxl-8 {
    margin-left: 7.5rem !important; }
  .ms-xxl-9 {
    margin-left: 9rem !important; }
  .ms-xxl-10 {
    margin-left: 11.5rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .m-xxl-n6 {
    margin: -4.5rem !important; }
  .m-xxl-n7 {
    margin: -6rem !important; }
  .m-xxl-n8 {
    margin: -7.5rem !important; }
  .m-xxl-n9 {
    margin: -9rem !important; }
  .m-xxl-n10 {
    margin: -11.5rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xxl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .mx-xxl-n10 {
    margin-right: -11.5rem !important;
    margin-left: -11.5rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xxl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .my-xxl-n10 {
    margin-top: -11.5rem !important;
    margin-bottom: -11.5rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -3rem !important; }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important; }
  .mt-xxl-n7 {
    margin-top: -6rem !important; }
  .mt-xxl-n8 {
    margin-top: -7.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -9rem !important; }
  .mt-xxl-n10 {
    margin-top: -11.5rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -3rem !important; }
  .me-xxl-n6 {
    margin-right: -4.5rem !important; }
  .me-xxl-n7 {
    margin-right: -6rem !important; }
  .me-xxl-n8 {
    margin-right: -7.5rem !important; }
  .me-xxl-n9 {
    margin-right: -9rem !important; }
  .me-xxl-n10 {
    margin-right: -11.5rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -9rem !important; }
  .mb-xxl-n10 {
    margin-bottom: -11.5rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -3rem !important; }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important; }
  .ms-xxl-n7 {
    margin-left: -6rem !important; }
  .ms-xxl-n8 {
    margin-left: -7.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -9rem !important; }
  .ms-xxl-n10 {
    margin-left: -11.5rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .p-xxl-6 {
    padding: 4.5rem !important; }
  .p-xxl-7 {
    padding: 6rem !important; }
  .p-xxl-8 {
    padding: 7.5rem !important; }
  .p-xxl-9 {
    padding: 9rem !important; }
  .p-xxl-10 {
    padding: 11.5rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xxl-10 {
    padding-right: 11.5rem !important;
    padding-left: 11.5rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xxl-10 {
    padding-top: 11.5rem !important;
    padding-bottom: 11.5rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pt-xxl-6 {
    padding-top: 4.5rem !important; }
  .pt-xxl-7 {
    padding-top: 6rem !important; }
  .pt-xxl-8 {
    padding-top: 7.5rem !important; }
  .pt-xxl-9 {
    padding-top: 9rem !important; }
  .pt-xxl-10 {
    padding-top: 11.5rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pe-xxl-6 {
    padding-right: 4.5rem !important; }
  .pe-xxl-7 {
    padding-right: 6rem !important; }
  .pe-xxl-8 {
    padding-right: 7.5rem !important; }
  .pe-xxl-9 {
    padding-right: 9rem !important; }
  .pe-xxl-10 {
    padding-right: 11.5rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important; }
  .pb-xxl-7 {
    padding-bottom: 6rem !important; }
  .pb-xxl-8 {
    padding-bottom: 7.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 9rem !important; }
  .pb-xxl-10 {
    padding-bottom: 11.5rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .ps-xxl-6 {
    padding-left: 4.5rem !important; }
  .ps-xxl-7 {
    padding-left: 6rem !important; }
  .ps-xxl-8 {
    padding-left: 7.5rem !important; }
  .ps-xxl-9 {
    padding-left: 9rem !important; }
  .ps-xxl-10 {
    padding-left: 11.5rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.78125rem !important; }
  .fs-2 {
    font-size: 1.54688rem !important; }
  .fs-3 {
    font-size: 1.3125rem !important; }
  .fs-4 {
    font-size: 1.07812rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.content {
  padding-top: 7rem !important;
  padding-bottom: 1.5rem !important; }

.custom-accordionwitharrow .card + .card {
  margin-top: 0.5rem; }

.custom-accordionwitharrow .card .card-header {
  background-color: transparent;
  position: relative; }

.custom-accordionwitharrow .accordion-arrow {
  position: absolute;
  right: 1.25rem; }

.custom-accordionwitharrow a.collapsed .accordion-arrow {
  transform: rotate(-90deg); }

.custom-accordion .card {
  overflow: visible;
  border-color: transparent !important;
  border-left: 2px dashed #dee2e6 !important;
  box-shadow: none;
  padding-left: 40px; }
  .custom-accordion .card .card-header {
    border-radius: 7px !important; }
    .custom-accordion .card .card-header:before {
      content: "";
      position: absolute;
      left: 6px;
      top: 22px;
      width: 42px;
      height: 2px;
      background: #dee2e6; }
  .custom-accordion .card .icon {
    position: absolute;
    left: -18px;
    top: -2px; }

.alert-dismissible {
  padding-right: 3.75rem; }

.alert-primary {
  color: #343a40;
  border-color: #05f;
  background-color: #ccddff; }

.alert-secondary {
  color: #343a40;
  border-color: #495057;
  background-color: #dbdcdd; }

.alert-success {
  color: #343a40;
  border-color: #3cd278;
  background-color: #d8f6e4; }

.alert-info {
  color: #343a40;
  border-color: #17a2b8;
  background-color: #d1ecf1; }

.alert-warning {
  color: #343a40;
  border-color: #ffc837;
  background-color: #fff4d7; }

.alert-danger {
  color: #343a40;
  border-color: #f0323c;
  background-color: #fcd6d8; }

.alert-light {
  color: #343a40;
  border-color: #f4f5f7;
  background-color: #fdfdfd; }

.alert-dark {
  color: #343a40;
  border-color: #343a40;
  background-color: #d6d8d9; }

.alert-orange {
  color: #343a40;
  border-color: #ff784b;
  background-color: #ffe4db; }

.alert-teal {
  color: #343a40;
  border-color: #20c997;
  background-color: #d2f4ea; }

.alert-gray-50 {
  color: #343a40;
  border-color: #fefefe;
  background-color: white; }

.badge-soft {
  color: #343a40; }

.badge-soft-primary {
  background-color: rgba(0, 85, 255, 0.12);
  color: #05f !important; }

.badge-soft-secondary {
  background-color: rgba(73, 80, 87, 0.12);
  color: #495057 !important; }

.badge-soft-success {
  background-color: rgba(60, 210, 120, 0.12);
  color: #3cd278 !important; }

.badge-soft-info {
  background-color: rgba(23, 162, 184, 0.12);
  color: #17a2b8 !important; }

.badge-soft-warning {
  background-color: rgba(255, 200, 55, 0.12);
  color: #ffc837 !important; }

.badge-soft-danger {
  background-color: rgba(240, 50, 60, 0.12);
  color: #f0323c !important; }

.badge-soft-light {
  background-color: rgba(244, 245, 247, 0.12);
  color: #f4f5f7 !important; }

.badge-soft-dark {
  background-color: rgba(52, 58, 64, 0.12);
  color: #343a40 !important; }

.badge-soft-orange {
  background-color: rgba(255, 120, 75, 0.12);
  color: #ff784b !important; }

.badge-soft-teal {
  background-color: rgba(32, 201, 151, 0.12);
  color: #20c997 !important; }

.badge-soft-gray-50 {
  background-color: rgba(254, 254, 254, 0.12);
  color: #fefefe !important; }

.badge.badge-light {
  color: #343a40; }

.badge.badge-pill {
  border-radius: 50px !important; }

.badge.badge-md {
  padding: 4px 16px;
  font-size: 16px; }

.badge.badge-lg {
  padding: 7px 20px;
  font-size: 17px; }

.bg-soft-primary {
  background-color: rgba(0, 85, 255, 0.12) !important; }

.bg-soft-secondary {
  background-color: rgba(73, 80, 87, 0.12) !important; }

.bg-soft-success {
  background-color: rgba(60, 210, 120, 0.12) !important; }

.bg-soft-info {
  background-color: rgba(23, 162, 184, 0.12) !important; }

.bg-soft-warning {
  background-color: rgba(255, 200, 55, 0.12) !important; }

.bg-soft-danger {
  background-color: rgba(240, 50, 60, 0.12) !important; }

.bg-soft-light {
  background-color: rgba(244, 245, 247, 0.12) !important; }

.bg-soft-dark {
  background-color: rgba(52, 58, 64, 0.12) !important; }

.bg-soft-orange {
  background-color: rgba(255, 120, 75, 0.12) !important; }

.bg-soft-teal {
  background-color: rgba(32, 201, 151, 0.12) !important; }

.bg-soft-gray-50 {
  background-color: rgba(254, 254, 254, 0.12) !important; }

.bg-gray-100 {
  background-color: #f4f5f7 !important; }

.bg-gray-200 {
  background-color: #f3f4f7 !important; }

.bg-gray-300 {
  background-color: #dee2e6 !important; }

.bg-gray-400 {
  background-color: #cdcdcd !important; }

.bg-gray-500 {
  background-color: #adb5bd !important; }

.bg-gray-600 {
  background-color: #787878 !important; }

.bg-gray-700 {
  background-color: #495057 !important; }

.bg-gray-800 {
  background-color: #343a40 !important; }

.bg-gray-900 {
  background-color: #212529 !important; }

.shape {
  position: absolute;
  pointer-events: none;
  overflow: hidden; }
  .shape.left {
    top: 0;
    bottom: 0;
    left: -1px; }
  .shape.right {
    top: 0;
    right: -1px;
    bottom: 0; }
  .shape.bottom {
    bottom: -2px;
    left: 0;
    right: 0; }
    .shape.bottom > svg {
      width: 100%; }
  .shape.top {
    top: -2px;
    left: 0;
    right: 0; }
    .shape.top > svg {
      width: 100%; }
  .shape > svg {
    width: auto;
    height: 100%; }

@media (max-width: 768px) {
  .shape.bottom {
    bottom: -5px; } }

.divider {
  position: absolute;
  pointer-events: none;
  overflow: hidden; }
  .divider.top {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 120' preserveAspectRatio='none'%3E%3Cg class='n2-ss-divider-end'%3E%3Cpath fill='RGBA(255,255,255,1)' opacity='0.25' d='M1920,0,0,120,0,0z'%3E%3C/path%3E%3Cpath fill='RGBA(255,255,255,1)' opacity='0.5' d='M1920,0,0,80,0,0z'%3E%3C/path%3E%3Cpath fill='RGBA(255,255,255,1)' d='M1920,-2,1920,0,0,40,0,-2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    transform: scaleX(-1); }
  .divider.bottom {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 120' preserveAspectRatio='none'%3E%3Cg class='n2-ss-divider-end'%3E%3Cpath fill='RGBA(255,255,255,1)' opacity='0.25' d='M1920,0,0,120,0,0z'%3E%3C/path%3E%3Cpath fill='RGBA(255,255,255,1)' opacity='0.5' d='M1920,0,0,80,0,0z'%3E%3C/path%3E%3Cpath fill='RGBA(255,255,255,1)' d='M1920,-2,1920,0,0,40,0,-2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    transform: scaleX(1) scaleY(-1); }

.animate {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out; }

.animate:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.bg-gradient {
  background: linear-gradient(180deg, rgba(0, 85, 255, 0.05) 0%, rgba(0, 85, 255, 0.02) 100%); }

.bg-gradient2 {
  background: linear-gradient(#f3f4f7 0%, #f4f5f7 100%) no-repeat left center; }

.bg-gradient3 {
  background: linear-gradient(#f4f5f7 0, #fafafb 100%); }

.bg-gradient4 {
  background: linear-gradient(rgba(255, 120, 75, 0.075) 0%, rgba(255, 200, 55, 0.1) 100%) no-repeat left center; }

.bg-gradient5 {
  background: linear-gradient(rgba(255, 120, 75, 0.05) 0%, rgba(255, 120, 75, 0.07) 100%) no-repeat left center; }

.bg-gradient6 {
  background: linear-gradient(rgba(0, 85, 255, 0.12) 0%, rgba(0, 85, 255, 0.02) 100%) no-repeat top center; }

.breadcrumb-no-bg {
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item {
  align-items: center !important; }
  .breadcrumb-item + .breadcrumb-item::before {
    vertical-align: middle;
    width: 1rem;
    height: 1rem; }

.btn .icon svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.1rem;
  margin-top: -2px; }

.btn-rounded {
  border-radius: 2.125rem; }

.btn-rounded-circle {
  border-radius: 50%; }

.btn-icon {
  padding: 0;
  width: 2.625rem;
  height: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
    height: 1.75rem;
    width: 1.75rem; }
  .btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
    height: 2.875rem;
    width: 2.875rem; }

.btn-link {
  font-weight: 400;
  color: #495057;
  background-color: transparent; }
  .btn-link:hover {
    color: #05f;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #787878;
    pointer-events: none; }

.btn-primary:hover, .btn-primary:focus {
  border-color: #05f;
  box-shadow: 0px 8px 20px -6px rgba(0, 85, 255, 0.6);
  background: #05f; }

.btn-outline-primary {
  color: #05f;
  border-color: #05f; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #05f;
    border-color: #05f; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #05f;
    border-color: #05f; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #05f;
    background-color: transparent; }
  .btn-outline-primary:hover, .btn-outline-primary:focus {
    box-shadow: 0px 8px 20px -6px rgba(0, 85, 255, 0.6); }

.btn-soft-primary {
  background-color: rgba(0, 85, 255, 0.1);
  color: #05f;
  border-color: transparent; }
  .btn-soft-primary:hover, .btn-soft-primary:focus {
    background-color: rgba(0, 85, 255, 0.15);
    border-color: rgba(0, 85, 255, 0.1);
    color: #05f;
    box-shadow: 0 1px 1px rgba(0, 85, 255, 0.1), 0 2px 4px 1px rgba(0, 85, 255, 0.1); }

.btn-secondary:hover, .btn-secondary:focus {
  border-color: #495057;
  box-shadow: 0px 8px 20px -6px rgba(73, 80, 87, 0.6);
  background: #495057; }

.btn-outline-secondary {
  color: #495057;
  border-color: #495057; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.15rem rgba(73, 80, 87, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #495057;
    border-color: #495057; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(73, 80, 87, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #495057;
    background-color: transparent; }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus {
    box-shadow: 0px 8px 20px -6px rgba(73, 80, 87, 0.6); }

.btn-soft-secondary {
  background-color: rgba(73, 80, 87, 0.1);
  color: #495057;
  border-color: transparent; }
  .btn-soft-secondary:hover, .btn-soft-secondary:focus {
    background-color: rgba(73, 80, 87, 0.15);
    border-color: rgba(73, 80, 87, 0.1);
    color: #495057;
    box-shadow: 0 1px 1px rgba(73, 80, 87, 0.1), 0 2px 4px 1px rgba(73, 80, 87, 0.1); }

.btn-success:hover, .btn-success:focus {
  border-color: #3cd278;
  box-shadow: 0px 8px 20px -6px rgba(60, 210, 120, 0.6);
  background: #3cd278; }

.btn-outline-success {
  color: #3cd278;
  border-color: #3cd278; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3cd278;
    border-color: #3cd278; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #3cd278;
    border-color: #3cd278; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(60, 210, 120, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #3cd278;
    background-color: transparent; }
  .btn-outline-success:hover, .btn-outline-success:focus {
    box-shadow: 0px 8px 20px -6px rgba(60, 210, 120, 0.6); }

.btn-soft-success {
  background-color: rgba(60, 210, 120, 0.1);
  color: #3cd278;
  border-color: transparent; }
  .btn-soft-success:hover, .btn-soft-success:focus {
    background-color: rgba(60, 210, 120, 0.15);
    border-color: rgba(60, 210, 120, 0.1);
    color: #3cd278;
    box-shadow: 0 1px 1px rgba(60, 210, 120, 0.1), 0 2px 4px 1px rgba(60, 210, 120, 0.1); }

.btn-info:hover, .btn-info:focus {
  border-color: #17a2b8;
  box-shadow: 0px 8px 20px -6px rgba(23, 162, 184, 0.6);
  background: #17a2b8; }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.15rem rgba(23, 162, 184, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:hover, .btn-outline-info:focus {
    box-shadow: 0px 8px 20px -6px rgba(23, 162, 184, 0.6); }

.btn-soft-info {
  background-color: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
  border-color: transparent; }
  .btn-soft-info:hover, .btn-soft-info:focus {
    background-color: rgba(23, 162, 184, 0.15);
    border-color: rgba(23, 162, 184, 0.1);
    color: #17a2b8;
    box-shadow: 0 1px 1px rgba(23, 162, 184, 0.1), 0 2px 4px 1px rgba(23, 162, 184, 0.1); }

.btn-warning:hover, .btn-warning:focus {
  border-color: #ffc837;
  box-shadow: 0px 8px 20px -6px rgba(255, 200, 55, 0.6);
  background: #ffc837; }

.btn-outline-warning {
  color: #ffc837;
  border-color: #ffc837; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc837;
    border-color: #ffc837; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 200, 55, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #212529;
    background-color: #ffc837;
    border-color: #ffc837; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 200, 55, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc837;
    background-color: transparent; }
  .btn-outline-warning:hover, .btn-outline-warning:focus {
    box-shadow: 0px 8px 20px -6px rgba(255, 200, 55, 0.6); }

.btn-soft-warning {
  background-color: rgba(255, 200, 55, 0.1);
  color: #ffc837;
  border-color: transparent; }
  .btn-soft-warning:hover, .btn-soft-warning:focus {
    background-color: rgba(255, 200, 55, 0.15);
    border-color: rgba(255, 200, 55, 0.1);
    color: #ffc837;
    box-shadow: 0 1px 1px rgba(255, 200, 55, 0.1), 0 2px 4px 1px rgba(255, 200, 55, 0.1); }

.btn-danger:hover, .btn-danger:focus {
  border-color: #f0323c;
  box-shadow: 0px 8px 20px -6px rgba(240, 50, 60, 0.6);
  background: #f0323c; }

.btn-outline-danger {
  color: #f0323c;
  border-color: #f0323c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f0323c;
    border-color: #f0323c; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #f0323c;
    border-color: #f0323c; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(240, 50, 60, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #f0323c;
    background-color: transparent; }
  .btn-outline-danger:hover, .btn-outline-danger:focus {
    box-shadow: 0px 8px 20px -6px rgba(240, 50, 60, 0.6); }

.btn-soft-danger {
  background-color: rgba(240, 50, 60, 0.1);
  color: #f0323c;
  border-color: transparent; }
  .btn-soft-danger:hover, .btn-soft-danger:focus {
    background-color: rgba(240, 50, 60, 0.15);
    border-color: rgba(240, 50, 60, 0.1);
    color: #f0323c;
    box-shadow: 0 1px 1px rgba(240, 50, 60, 0.1), 0 2px 4px 1px rgba(240, 50, 60, 0.1); }

.btn-light:hover, .btn-light:focus {
  border-color: #f4f5f7;
  box-shadow: 0px 8px 20px -6px rgba(244, 245, 247, 0.6);
  background: #f4f5f7; }

.btn-outline-light {
  color: #f4f5f7;
  border-color: #f4f5f7; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f4f5f7;
    border-color: #f4f5f7; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(244, 245, 247, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #212529;
    background-color: #f4f5f7;
    border-color: #f4f5f7; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(244, 245, 247, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f4f5f7;
    background-color: transparent; }
  .btn-outline-light:hover, .btn-outline-light:focus {
    box-shadow: 0px 8px 20px -6px rgba(244, 245, 247, 0.6); }

.btn-soft-light {
  background-color: rgba(244, 245, 247, 0.1);
  color: #f4f5f7;
  border-color: transparent; }
  .btn-soft-light:hover, .btn-soft-light:focus {
    background-color: rgba(244, 245, 247, 0.15);
    border-color: rgba(244, 245, 247, 0.1);
    color: #f4f5f7;
    box-shadow: 0 1px 1px rgba(244, 245, 247, 0.1), 0 2px 4px 1px rgba(244, 245, 247, 0.1); }

.btn-dark:hover, .btn-dark:focus {
  border-color: #343a40;
  box-shadow: 0px 8px 20px -6px rgba(52, 58, 64, 0.6);
  background: #343a40; }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:hover, .btn-outline-dark:focus {
    box-shadow: 0px 8px 20px -6px rgba(52, 58, 64, 0.6); }

.btn-soft-dark {
  background-color: rgba(52, 58, 64, 0.1);
  color: #343a40;
  border-color: transparent; }
  .btn-soft-dark:hover, .btn-soft-dark:focus {
    background-color: rgba(52, 58, 64, 0.15);
    border-color: rgba(52, 58, 64, 0.1);
    color: #343a40;
    box-shadow: 0 1px 1px rgba(52, 58, 64, 0.1), 0 2px 4px 1px rgba(52, 58, 64, 0.1); }

.btn-orange:hover, .btn-orange:focus {
  border-color: #ff784b;
  box-shadow: 0px 8px 20px -6px rgba(255, 120, 75, 0.6);
  background: #ff784b; }

.btn-outline-orange {
  color: #ff784b;
  border-color: #ff784b; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #ff784b;
    border-color: #ff784b; }
  .btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 120, 75, 0.5); }
  .btn-check:checked + .btn-outline-orange,
  .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
    color: #fff;
    background-color: #ff784b;
    border-color: #ff784b; }
    .btn-check:checked + .btn-outline-orange:focus,
    .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 120, 75, 0.5); }
  .btn-outline-orange:disabled, .btn-outline-orange.disabled {
    color: #ff784b;
    background-color: transparent; }
  .btn-outline-orange:hover, .btn-outline-orange:focus {
    box-shadow: 0px 8px 20px -6px rgba(255, 120, 75, 0.6); }

.btn-soft-orange {
  background-color: rgba(255, 120, 75, 0.1);
  color: #ff784b;
  border-color: transparent; }
  .btn-soft-orange:hover, .btn-soft-orange:focus {
    background-color: rgba(255, 120, 75, 0.15);
    border-color: rgba(255, 120, 75, 0.1);
    color: #ff784b;
    box-shadow: 0 1px 1px rgba(255, 120, 75, 0.1), 0 2px 4px 1px rgba(255, 120, 75, 0.1); }

.btn-teal:hover, .btn-teal:focus {
  border-color: #20c997;
  box-shadow: 0px 8px 20px -6px rgba(32, 201, 151, 0.6);
  background: #20c997; }

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-check:focus + .btn-outline-teal, .btn-outline-teal:focus {
    box-shadow: 0 0 0 0.15rem rgba(32, 201, 151, 0.5); }
  .btn-check:checked + .btn-outline-teal,
  .btn-check:active + .btn-outline-teal, .btn-outline-teal:active, .btn-outline-teal.active, .btn-outline-teal.dropdown-toggle.show {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-check:checked + .btn-outline-teal:focus,
    .btn-check:active + .btn-outline-teal:focus, .btn-outline-teal:active:focus, .btn-outline-teal.active:focus, .btn-outline-teal.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(32, 201, 151, 0.5); }
  .btn-outline-teal:disabled, .btn-outline-teal.disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-teal:hover, .btn-outline-teal:focus {
    box-shadow: 0px 8px 20px -6px rgba(32, 201, 151, 0.6); }

.btn-soft-teal {
  background-color: rgba(32, 201, 151, 0.1);
  color: #20c997;
  border-color: transparent; }
  .btn-soft-teal:hover, .btn-soft-teal:focus {
    background-color: rgba(32, 201, 151, 0.15);
    border-color: rgba(32, 201, 151, 0.1);
    color: #20c997;
    box-shadow: 0 1px 1px rgba(32, 201, 151, 0.1), 0 2px 4px 1px rgba(32, 201, 151, 0.1); }

.btn-gray-50:hover, .btn-gray-50:focus {
  border-color: #fefefe;
  box-shadow: 0px 8px 20px -6px rgba(254, 254, 254, 0.6);
  background: #fefefe; }

.btn-outline-gray-50 {
  color: #fefefe;
  border-color: #fefefe; }
  .btn-outline-gray-50:hover {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
  .btn-check:focus + .btn-outline-gray-50, .btn-outline-gray-50:focus {
    box-shadow: 0 0 0 0.15rem rgba(254, 254, 254, 0.5); }
  .btn-check:checked + .btn-outline-gray-50,
  .btn-check:active + .btn-outline-gray-50, .btn-outline-gray-50:active, .btn-outline-gray-50.active, .btn-outline-gray-50.dropdown-toggle.show {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
    .btn-check:checked + .btn-outline-gray-50:focus,
    .btn-check:active + .btn-outline-gray-50:focus, .btn-outline-gray-50:active:focus, .btn-outline-gray-50.active:focus, .btn-outline-gray-50.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(254, 254, 254, 0.5); }
  .btn-outline-gray-50:disabled, .btn-outline-gray-50.disabled {
    color: #fefefe;
    background-color: transparent; }
  .btn-outline-gray-50:hover, .btn-outline-gray-50:focus {
    box-shadow: 0px 8px 20px -6px rgba(254, 254, 254, 0.6); }

.btn-soft-gray-50 {
  background-color: rgba(254, 254, 254, 0.1);
  color: #fefefe;
  border-color: transparent; }
  .btn-soft-gray-50:hover, .btn-soft-gray-50:focus {
    background-color: rgba(254, 254, 254, 0.15);
    border-color: rgba(254, 254, 254, 0.1);
    color: #fefefe;
    box-shadow: 0 1px 1px rgba(254, 254, 254, 0.1), 0 2px 4px 1px rgba(254, 254, 254, 0.1); }

.btn-white {
  border-color: #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.375rem; }
  .btn-white:hover, .btn-white:focus {
    color: #343a40 !important;
    background-color: #fff !important;
    box-shadow: 0 1px 3px rgba(60, 72, 88, 0.15), 0 2px 4px 2px rgba(60, 72, 88, 0.18); }

.btn-back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: none;
  z-index: 10; }
  .btn-back-to-top.show {
    display: flex; }

.btn-play {
  width: 3.5rem;
  height: 3.5rem;
  background: radial-gradient(#3cd278 98%, white 100%);
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
  /* triangle */
  /* pulse wave */ }
  .btn-play.primary {
    background: radial-gradient(#0055ff 98%, white 100%); }
  .btn-play.secondary {
    background: radial-gradient(#495057 98%, white 100%); }
  .btn-play.success {
    background: radial-gradient(#3cd278 98%, white 100%); }
  .btn-play.info {
    background: radial-gradient(#17a2b8 98%, white 100%); }
  .btn-play.warning {
    background: radial-gradient(#ffc837 98%, white 100%); }
  .btn-play.danger {
    background: radial-gradient(#f0323c 98%, white 100%); }
  .btn-play.light {
    background: radial-gradient(#f4f5f7 98%, white 100%); }
  .btn-play.dark {
    background: radial-gradient(#343a40 98%, white 100%); }
  .btn-play.orange {
    background: radial-gradient(#ff784b 98%, white 100%); }
  .btn-play.teal {
    background: radial-gradient(#20c997 98%, white 100%); }
  .btn-play.gray-50 {
    background: radial-gradient(#fefefe 98%, white 100%); }
  .btn-play::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.75rem solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .btn-play::before {
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    opacity: 1;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.75);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
    animation-delay: 0s;
    animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps; }

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none; } }

.card {
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 0 0 0.25rem rgba(73, 80, 87, 0.05); }
  .card.hoverable {
    transition: all 0.3s ease-in-out; }
    .card.hoverable:hover {
      box-shadow: 0rem 0rem 1.5rem 0px rgba(0, 0, 0, 0.12);
      transform: translateY(-3px) !important; }
  .card .card-img-overlay .overlay-light-top {
    padding: 1rem 1rem 1.5rem 1rem;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.95) 50%, rgba(255, 255, 255, 0.9) 75%, transparent 100%); }
  .card .card-img-overlay .overlay-light-bottom {
    position: absolute;
    bottom: 0;
    padding: 1.5rem 1rem 1rem 1rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.85) 85%, transparent 100%); }

.card-footer {
  border-color: #f6f7f9; }

.card-drop {
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  color: inherit;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  margin-top: -6px; }
  .card-drop:hover {
    background-color: #f3f4f7;
    color: #343a40; }

.card-title,
.card-header {
  margin-top: 0; }

.card-nav {
  margin: -0.5rem 0; }

.card-box {
  background-color: #fff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.1875rem; }

.card-img-top-overlay {
  position: relative; }
  .card-img-top-overlay .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(120, 120, 120, 0.4); }
  .card-img-top-overlay .overlay-light {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(243, 244, 247, 0.5); }
  .card-img-top-overlay .overlay-dark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(33, 37, 41, 0.5); }
  .card-img-top-overlay .card-overlay-top {
    position: absolute;
    top: .75rem;
    right: 1.25rem;
    left: 1.25rem; }
  .card-img-top-overlay .card-overlay-bottom {
    position: absolute;
    right: 1.25rem;
    bottom: .75rem;
    left: 1.25rem; }
  .card-img-top-overlay .card-badge {
    position: absolute;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    font-weight: 600;
    box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    z-index: 1000; }
    .card-img-top-overlay .card-badge.top-left {
      top: 1rem;
      left: 1rem; }
    .card-img-top-overlay .card-badge.bottom-left {
      bottom: 1rem;
      left: 1rem; }
    .card-img-top-overlay .card-badge.top-right {
      top: 1rem;
      right: 1rem; }
    .card-img-top-overlay .card-badge.bottom-right {
      bottom: 1rem;
      right: 1rem; }

.card-portfolio-item .card-zoom {
  overflow: hidden !important;
  border-radius: inherit; }
  .card-portfolio-item .card-zoom img {
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
    border-radius: inherit; }

.card-portfolio-item:hover .card-zoom img {
  transform: scale(1.1); }

.card-listing-item {
  overflow: hidden !important;
  border: 1px solid #dee2e6 !important; }
  .card-listing-item.overlay .overlay-details {
    position: absolute;
    opacity: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.25);
    transform: translateY(10%);
    transition: all 0.3s ease-in-out; }
  .card-listing-item.overlay:hover .overlay-details {
    opacity: 1;
    transform: translateY(0px); }

.dropdown .dropdown-menu {
  font-size: 0.9375rem;
  z-index: 1030;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
  padding: 0.5rem 0.3rem;
  top: 100%; }
  .dropdown .dropdown-menu.dropdown-menu-sm {
    width: 5rem; }
  .dropdown .dropdown-menu.dropdown-menu-lg {
    width: 20rem; }
  .dropdown .dropdown-menu.dropdown-menu-xl {
    width: 30rem; }
  .dropdown .dropdown-menu .dropdown-item {
    border-radius: 0.25rem; }

.dropdown.btn-group .btn:hover, .dropdown.btn-group .btn:active, .dropdown.btn-group .btn:focus {
  transform: translate3d(0, 0px, 0) !important; }

.form-control-light {
  background-color: #f4f5f7;
  border-color: #f4f5f7; }

input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus,
.custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important; }

.comment-area-box .form-control {
  border-color: #dee2e6;
  border-radius: 0.25rem 0.25rem 0 0; }

.comment-area-box .comment-area-btn {
  background-color: #f4f5f7;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem; }

input:focus {
  outline: none; }

.form-control-with-hint {
  position: relative; }
  .form-control-with-hint .form-control {
    padding-right: 50px !important; }
  .form-control-with-hint .form-control-feedback {
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 0.75rem;
    display: flex;
    align-items: center;
    background: transparent;
    font-size: 0.75rem;
    font-weight: 600;
    color: #6c7681; }

.form-check .form-check-input:checked ~ .form-label::after {
  background-image: none;
  font-family: "unicons";
  content: '\e8d9';
  font-size: 1.25rem;
  top: -0.185rem;
  left: -1.7rem;
  color: #ffffff; }

.modal .modal-content .modal-body {
  padding: 0 1rem 1rem 1rem; }

.modal-title {
  margin-top: 0; }

.modal-full {
  width: 92%;
  max-width: none; }

.modal-demo {
  background-color: #fff;
  width: 600px !important;
  border-radius: 4px;
  display: none;
  position: relative; }
  .modal-demo .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: #f4f5f7; }

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important; } }

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: #343a40;
  color: #fff;
  text-align: left;
  margin: 0; }

.custom-modal-text {
  padding: 20px; }

.custombox-modal-wrapper {
  text-align: left; }

.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999; }

.navbar {
  padding: 0; }
  .navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 0.9375rem; }
    .navbar .navbar-nav .nav-link [class^="icon-"] {
      color: #495057; }
      .navbar .navbar-nav .nav-link [class^="icon-"] > svg [fill]:not([fill="none"]) {
        fill: #495057; }
    .navbar .navbar-nav .nav-link.active {
      color: #05f !important; }
      .navbar .navbar-nav .nav-link.active [class^="icon-"] {
        color: #05f; }
        .navbar .navbar-nav .nav-link.active [class^="icon-"] > svg [fill]:not([fill="none"]) {
          fill: #05f; }
    .navbar .navbar-nav .nav-link:hover [class^="icon-"] {
      color: #05f; }
      .navbar .navbar-nav .nav-link:hover [class^="icon-"] > svg [fill]:not([fill="none"]) {
        fill: #05f; }
  .navbar.topnav-menu {
    height: 70px; }
    .navbar.topnav-menu .navbar-nav > .nav-item > .nav-link {
      padding: 0 1rem;
      display: block;
      line-height: 70px;
      max-height: 70px; }
  .navbar.navbar-sticky {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06);
    background: #fff;
    z-index: 100000;
    position: fixed;
    width: 100%;
    top: 0; }

.navbar-light .logo .logo-dark {
  display: inline-block; }

.navbar-light .logo .logo-light {
  display: none; }

.navbar-dark .logo .logo-dark {
  display: none; }

.navbar-dark .logo .logo-light {
  display: inline-block; }

.navbar .navbar-toggler:focus {
  box-shadow: none; }

.navbar-nav .dropdown-menu {
  min-width: 0;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none; }
  @media (min-width: 992px) {
    .navbar-nav .dropdown-menu {
      left: 50%;
      min-width: calc(10rem + 3rem);
      padding: 0.75rem 0;
      box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
      visibility: hidden;
      opacity: 0;
      transform: translate3d(-50%, 30px, 0);
      transition: all 0.3s ease-in-out;
      transition-property: opacity, visibility, transform, -webkit-transform;
      transform-origin: top center; }
      .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 30rem; }
      .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 48rem; }
      .navbar-nav .dropdown-menu .nav .nav-item.dropdown .dropdown-menu {
        position: absolute;
        top: 0;
        left: 150%;
        display: none; } }
  .navbar-nav .dropdown-menu .nav {
    display: block; }
    .navbar-nav .dropdown-menu .nav .nav-item .nav-link {
      padding: 0.25rem 0.75rem;
      font-weight: normal;
      margin: 0 0.5rem;
      position: relative; }
    .navbar-nav .dropdown-menu .nav .nav-item.dropdown:hover > .dropdown-menu.show {
      display: block; }
    .navbar-nav .dropdown-menu .nav .nav-item.dropdown .arrow::after {
      right: 22px;
      transform: rotate(-135deg) translateY(-50%);
      position: absolute; }
  .navbar-nav .dropdown-menu .arrow {
    display: inline-block; }
    .navbar-nav .dropdown-menu .arrow:after {
      border-color: initial;
      border-style: solid;
      border-width: 0 0 1px 1px;
      content: "";
      height: 0.4rem;
      display: inline-block;
      right: 5px;
      top: 50%;
      margin-left: 10px;
      transform: rotate(-45deg) translateY(-50%);
      transform-origin: top;
      transition: all 0.3s ease-out;
      width: 0.4rem; }

@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu {
    z-index: 1030;
    display: block; }
  .navbar-nav .dropdown:hover .dropdown-menu {
    opacity: 1;
    transform: translate3d(-50%, 1px, 0);
    visibility: visible; }
    .navbar-nav .dropdown:hover .dropdown-menu .dropdown:hover .dropdown-menu {
      opacity: 1;
      transform: translate3d(-50%, 1px, 0);
      visibility: visible;
      display: block; } }

.navbar-dark .navbar-brand h4, .navbar-dark .navbar-brand .h4 {
  color: #fff; }

.navbar-dark .dropdown-menu .nav-link {
  color: #495057 !important; }

.nav-title {
  padding: 0.25rem 0.75rem;
  margin: 0 0.5rem; }

@media (max-width: 991.98px) {
  .navbar.topnav-menu {
    background-color: #fff;
    padding: 8px 0;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1001; }
    .navbar.topnav-menu .dropdown .dropdown-menu.dropdown-menu-lg {
      width: 100%; }
    .navbar.topnav-menu.navbar-dark .logo .logo-dark {
      display: inline-block; }
    .navbar.topnav-menu.navbar-dark .logo .logo-light {
      display: none; }
    .navbar.topnav-menu.navbar-dark .navbar-toggler {
      color: #32373b;
      border-color: transparent; }
    .navbar.topnav-menu.navbar-dark .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2332373b' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
    .navbar.topnav-menu .navbar-nav > .nav-item > .nav-link {
      line-height: inherit;
      padding: 0.5rem 1em;
      color: #212529; }
      .navbar.topnav-menu .navbar-nav > .nav-item > .nav-link .feather {
        float: right; }
    .navbar.topnav-menu .navbar-nav > .nav-item .btn.btn-white.text-white {
      color: #343a40 !important; }
    .navbar.topnav-menu .navbar-nav > .dropdown .dropdown-menu .nav .dropdown .dropdown-menu .nav .nav-item > .nav-link {
      margin: 0 0.5rem 0 1.2rem; }
    .navbar.topnav-menu .navbar-nav .dropdown-menu .arrow:after {
      transform: rotate(-45deg) translateY(-50%) !important; }
  .navbar-collapse {
    max-height: 480px;
    overflow-y: auto; } }

.nav-tabs {
  border-bottom: 2px solid rgba(120, 120, 120, 0.2); }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs li a {
    border-color: transparent !important;
    padding: 0.625rem 1.5rem; }
  .nav-tabs li a.active {
    border-bottom: 2px solid #05f !important; }

.nav-tabs > li > a, .nav-pills > li > a {
  color: #495057;
  font-weight: 500; }

.nav-pills {
  background: #fff;
  border-radius: 0.25rem;
  padding: 6px; }
  .nav-pills .nav-link {
    background-color: transparent;
    color: #495057; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #495057;
    background-color: #fff;
    box-shadow: 0 0 0.25rem rgba(73, 80, 87, 0.05); }

.navtab-bg {
  background-color: #f4f5f7 !important; }

.tab-content {
  padding: 20px 0 0 0; }

.nav .nav-link {
  color: #495057; }
  .nav .nav-link:hover, .nav .nav-link:focus, .nav .nav-link:active {
    color: #05f; }
  .nav .nav-link.active {
    color: #05f; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-rounded .page-link {
  border-radius: 100% !important;
  margin: 0 3px; }

.popover {
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08); }
  .popover .popover-header {
    margin-top: 0;
    font-size: 0.82031rem; }

.progress-sm {
  height: 5px; }

.progress-md {
  height: 8px; }

.progress-lg {
  height: 12px; }

.progress-xl {
  height: 15px; }

.progress-xxl {
  height: 25px; }

/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px; }
  .progress-vertical .progress-bar {
    width: 100%; }
  .progress-vertical.progress-xl {
    width: 15px; }
  .progress-vertical.progress-lg {
    width: 12px; }
  .progress-vertical.progress-md {
    width: 8px; }
  .progress-vertical.progress-sm {
    width: 5px; }

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px; }
  .progress-vertical-bottom .progress-bar {
    width: 100%;
    bottom: 0;
    position: absolute; }
  .progress-vertical-bottom.progress-xl {
    width: 15px; }
  .progress-vertical-bottom.progress-lg {
    width: 12px; }
  .progress-vertical-bottom.progress-md {
    width: 8px; }
  .progress-vertical-bottom.progress-sm {
    width: 5px; }

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px; }
  .progress-w-percent .progress {
    width: 80%;
    float: left;
    margin-top: 8px; }
  .progress-w-percent .progress-value {
    width: 20%;
    float: right;
    text-align: right;
    line-height: 20px; }

body {
  letter-spacing: 0.02rem;
  font-family: "Be Vietnam Pro", sans-serif; }

a {
  text-decoration: none !important;
  display: inline-block; }
  a:focus {
    outline: none; }

.row > * {
  position: relative; }

label {
  font-weight: 600;
  font-size: 0.82031rem; }

button:focus {
  outline: none; }

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid #f3f4f7; }
  .blockquote.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid #f3f4f7;
    text-align: right; }

.zindex-1 {
  z-index: 1; }

.zindex-5 {
  z-index: 5; }

.zindex-10 {
  z-index: 10; }

.zindex-20 {
  z-index: 20; }

.table-centered td, .table-centered th {
  vertical-align: middle !important; }

.table th {
  font-weight: 600; }

.table .table-user img {
  height: 30px;
  width: 30px; }

.action-icon {
  color: #787878;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px; }
  .action-icon:hover {
    color: #495057; }

.table-nowrap th, .table-nowrap td {
  white-space: nowrap; }

.custom-table .select-col {
  width: 100px; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 10px 0;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #343a40;
  font-weight: 600; }

p {
  line-height: 1.6; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-17 {
  font-size: 17px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-19 {
  font-size: 19px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-24 {
  font-size: 24px !important; }

.text-unline-dashed {
  border-bottom: #cdcdcd 1px dashed !important;
  padding-bottom: 1px; }

.line-height-normal {
  line-height: 1; }

.fw-medium {
  font-weight: 500; }

.fw-semibold {
  font-weight: 600; }

.highlight {
  position: relative;
  z-index: 1;
  width: fit-content; }
  .highlight::before {
    content: '';
    display: block;
    position: absolute;
    background: #f4f5f7;
    width: 100%;
    height: 1.2rem;
    z-index: -1;
    right: -.5rem;
    bottom: .35rem; }
  .highlight.highlight-primary::before {
    background: #80aaff !important; }
  .highlight.highlight-secondary::before {
    background: #869099 !important; }
  .highlight.highlight-success::before {
    background: #a4eac0 !important; }
  .highlight.highlight-info::before {
    background: #63d9ec !important; }
  .highlight.highlight-warning::before {
    background: #ffebb7 !important; }
  .highlight.highlight-danger::before {
    background: #f9a9ad !important; }
  .highlight.highlight-light::before {
    background: white !important; }
  .highlight.highlight-dark::before {
    background: #6d7a86 !important; }
  .highlight.highlight-orange::before {
    background: #ffd8cb !important; }
  .highlight.highlight-teal::before {
    background: #7eeaca !important; }
  .highlight.highlight-gray-50::before {
    background: white !important; }

.width-xs {
  min-width: 80px; }

.width-sm {
  min-width: 95px; }

.width-md {
  min-width: 110px; }

.width-lg {
  min-width: 140px; }

.width-xl {
  min-width: 160px; }

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.sp-line-1 {
  -webkit-line-clamp: 1; }

.sp-line-2 {
  -webkit-line-clamp: 2; }

.sp-line-3 {
  -webkit-line-clamp: 3; }

.sp-line-4 {
  -webkit-line-clamp: 4; }

.pull-in {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.rounded-sm {
  border-radius: 0.25rem; }

.rounded-lg {
  border-radius: 0.5rem; }

.list-with-separator li::after {
  content: "-";
  color: #cdcdcd;
  font-weight: 900;
  margin: 0 10px; }

.list-with-separator li:last-child::after {
  content: ''; }

.widget-flat {
  position: relative;
  overflow: hidden; }
  .widget-flat i.widget-icon {
    font-size: 36px; }

/* Chat widget */
.conversation-list {
  list-style: none;
  height: 332px;
  padding: 0 7px; }
  .conversation-list li {
    margin-bottom: 24px; }
  .conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 34px; }
    .conversation-list .chat-avatar img {
      border-radius: 100%;
      width: 100%; }
    .conversation-list .chat-avatar i {
      font-size: 12px;
      font-style: normal; }
  .conversation-list .ctext-wrap {
    background: #a3c2ff;
    color: #05f;
    border-radius: 0.375rem;
    display: inline-block;
    padding: 4px 12px;
    position: relative; }
    .conversation-list .ctext-wrap i {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      position: relative; }
    .conversation-list .ctext-wrap p {
      margin: 0;
      padding-top: 3px; }
    .conversation-list .ctext-wrap:after {
      right: 99%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #a3c2ff;
      border-width: 6px;
      margin-left: -1px;
      border-right-color: #a3c2ff; }
  .conversation-list .conversation-text {
    float: left;
    font-size: 12px;
    margin-left: 12px;
    width: 70%; }
  .conversation-list .odd .chat-avatar {
    float: right !important; }
  .conversation-list .odd .conversation-text {
    float: right !important;
    margin-right: 12px;
    text-align: right;
    width: 70% !important; }
  .conversation-list .odd .ctext-wrap {
    background-color: #dee2e6;
    color: #495057; }
    .conversation-list .odd .ctext-wrap:after {
      border-color: transparent;
      border-left-color: #dee2e6;
      border-top-color: #dee2e6;
      left: 99% !important; }

/* profile / users */
.profile-widget {
  position: relative; }
  .profile-widget .card-action {
    position: absolute;
    right: 7px;
    top: 12px;
    z-index: 100; }
  .profile-widget .card-img-top {
    max-height: 220px; }
  .profile-widget .profile-info {
    margin-top: -2.7rem; }

.calendar-widget .flatpickr-calendar {
  box-shadow: none;
  border: 1px solid #dee2e6 !important; }

.checkbox label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
  margin-bottom: 0; }
  .checkbox label::before {
    background-color: #dee2e6;
    border-radius: 3px;
    border: 2px solid #dee2e6;
    content: "";
    display: inline-block;
    height: 18px;
    left: 0;
    margin-left: -18px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 18px;
    outline: none !important;
    top: 2px; }
  .checkbox label::after {
    color: #495057;
    display: inline-block;
    font-size: 11px;
    height: 18px;
    left: 0;
    margin-left: -18px;
    padding-left: 3px;
    padding-top: 2px;
    position: absolute;
    top: 0;
    width: 18px; }

.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important; }
  .checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; }

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none; }

.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 7px;
  display: table;
  width: 4px;
  height: 8px;
  border: 2px solid #495057;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #f4f5f7;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute; }

.checkbox.checkbox-single label {
  height: 18px;
  width: 18px; }
  .checkbox.checkbox-single label:before {
    margin-left: 0; }
  .checkbox.checkbox-single label:after {
    margin-left: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #05f;
  border-color: #05f; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  background-color: #495057;
  border-color: #495057; }

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #3cd278;
  border-color: #3cd278; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffc837;
  border-color: #ffc837; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f0323c;
  border-color: #f0323c; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-light input[type="checkbox"]:checked + label::before {
  background-color: #f4f5f7;
  border-color: #f4f5f7; }

.checkbox-light input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-dark input[type="checkbox"]:checked + label::before {
  background-color: #343a40;
  border-color: #343a40; }

.checkbox-dark input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-orange input[type="checkbox"]:checked + label::before {
  background-color: #ff784b;
  border-color: #ff784b; }

.checkbox-orange input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-teal input[type="checkbox"]:checked + label::before {
  background-color: #20c997;
  border-color: #20c997; }

.checkbox-teal input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.checkbox-gray-50 input[type="checkbox"]:checked + label::before {
  background-color: #fefefe;
  border-color: #fefefe; }

.checkbox-gray-50 input[type="checkbox"]:checked + label::after {
  border-color: #fff; }

.radio label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
  margin-bottom: 0; }
  .radio label::before {
    -o-transition: border 0.5s ease-in-out;
    -webkit-transition: border 0.5s ease-in-out;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #787878;
    content: "";
    display: inline-block;
    height: 18px;
    left: 0;
    margin-left: -18px;
    position: absolute;
    transition: border 0.5s ease-in-out;
    width: 18px;
    outline: none !important; }
  .radio label::after {
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -webkit-transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    background-color: #495057;
    border-radius: 50%;
    content: " ";
    display: inline-block;
    height: 10px;
    left: 6px;
    margin-left: -20px;
    position: absolute;
    top: 4px;
    transform: scale(0, 0);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    width: 10px; }

.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important; }
  .radio input[type="radio"]:disabled + label {
    opacity: 0.65; }

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted; }

.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio.radio-single label {
  height: 17px; }

.radio-primary input[type="radio"] + label::after {
  background-color: #05f; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #05f; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #05f; }

.radio-secondary input[type="radio"] + label::after {
  background-color: #495057; }

.radio-secondary input[type="radio"]:checked + label::before {
  border-color: #495057; }

.radio-secondary input[type="radio"]:checked + label::after {
  background-color: #495057; }

.radio-success input[type="radio"] + label::after {
  background-color: #3cd278; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #3cd278; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #3cd278; }

.radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.radio-warning input[type="radio"] + label::after {
  background-color: #ffc837; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc837; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc837; }

.radio-danger input[type="radio"] + label::after {
  background-color: #f0323c; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f0323c; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f0323c; }

.radio-light input[type="radio"] + label::after {
  background-color: #f4f5f7; }

.radio-light input[type="radio"]:checked + label::before {
  border-color: #f4f5f7; }

.radio-light input[type="radio"]:checked + label::after {
  background-color: #f4f5f7; }

.radio-dark input[type="radio"] + label::after {
  background-color: #343a40; }

.radio-dark input[type="radio"]:checked + label::before {
  border-color: #343a40; }

.radio-dark input[type="radio"]:checked + label::after {
  background-color: #343a40; }

.radio-orange input[type="radio"] + label::after {
  background-color: #ff784b; }

.radio-orange input[type="radio"]:checked + label::before {
  border-color: #ff784b; }

.radio-orange input[type="radio"]:checked + label::after {
  background-color: #ff784b; }

.radio-teal input[type="radio"] + label::after {
  background-color: #20c997; }

.radio-teal input[type="radio"]:checked + label::before {
  border-color: #20c997; }

.radio-teal input[type="radio"]:checked + label::after {
  background-color: #20c997; }

.radio-gray-50 input[type="radio"] + label::after {
  background-color: #fefefe; }

.radio-gray-50 input[type="radio"]:checked + label::before {
  border-color: #fefefe; }

.radio-gray-50 input[type="radio"]:checked + label::after {
  background-color: #fefefe; }

@media print {
  .left-side-menu,
  .right-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none; }
  .card-body,
  .content-page,
  .right-bar,
  .content,
  body {
    padding: 0;
    margin: 0; } }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999; }

#status {
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px; }

.spinner > div {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #05f;
  border-radius: 50%;
  animation: circle-loader 1s infinite ease-in-out both;
  margin: 0px 7px; }

.spinner .circle1 {
  animation-delay: -0.32s; }

.spinner .circle2 {
  animation-delay: -0.16s; }

@keyframes circle-loader {
  0%, 80%, 100% {
    transform: scale(0.4); }
  40% {
    transform: scale(1); } }

.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none; }
  .rateit .rateit-range * {
    display: block; }
  .rateit .rateit-hover,
  .rateit .rateit-selected {
    position: absolute;
    left: 0;
    top: 0;
    width: 0; }
  .rateit .rateit-hover-rtl,
  .rateit .rateit-selected-rtl {
    left: auto;
    right: 0; }
  .rateit .rateit-hover {
    color: #ffc837; }
  .rateit .rateit-hover-rtl {
    background-position: right -32px; }
  .rateit .rateit-selected {
    color: #f0323c; }
  .rateit .rateit-selected-rtl {
    background-position: right -16px; }
  .rateit .rateit-preset {
    color: #ffc837; }
  .rateit button.rateit-reset {
    width: 16px;
    height: 16px;
    float: left;
    outline: none;
    border: none;
    padding: 0; }
  .rateit .rateit-reset span {
    display: none; }
  .rateit .rateit-range {
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    height: 16px;
    outline: none; }
  .rateit.rateit-font .rateit-reset {
    width: .6em;
    height: .6em;
    margin-right: 5px;
    margin-top: 5px;
    background: #dee2e6;
    border-radius: 50%;
    position: relative; }
    .rateit.rateit-font .rateit-reset span {
      display: block;
      height: calc(50% - 0.045em);
      top: 2px;
      position: absolute;
      border-bottom: 2px solid #495057;
      width: 50%;
      margin-left: 25%;
      margin-right: 25%; }
  .rateit.rateit-font .rateit-reset:hover,
  .rateit.rateit-font button.rateit-reset:focus {
    background: #f0323c; }
    .rateit.rateit-font .rateit-reset:hover span,
    .rateit.rateit-font button.rateit-reset:focus span {
      border-color: #fff; }

.rateit-font {
  font-size: 24px;
  line-height: 1em; }
  .rateit-font .rateit-range {
    background: none;
    height: auto; }
    .rateit-font .rateit-range > div {
      background: none;
      overflow: hidden;
      cursor: default;
      white-space: nowrap; }
  .rateit-font .rateit-empty {
    color: #cdcdcd; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  width: 18px;
  height: 18px;
  background: center center url(/assets/images/shapes/x.svg) no-repeat;
  top: 12px; }

.mfp-arrow:before {
  width: 60px;
  height: 60px;
  opacity: .9;
  background: center center url("/assets/images/shapes/chevron-left.svg") no-repeat; }

.mfp-arrow:before, .mfp-arrow:after {
  border: none; }

.mfp-arrow-left {
  left: 1rem; }

.mfp-arrow-right {
  right: 1rem; }
  .mfp-arrow-right:before {
    background: center center url("/assets/images/shapes/chevron-right.svg") no-repeat; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  backface-visibility: hidden;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

.daterangepicker {
  font-family: "Be Vietnam Pro", sans-serif;
  border: 0 solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06);
  background-color: #fff; }
  .daterangepicker .calendar-table {
    border: 1px solid #fff;
    background-color: #fff; }
    .daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
      color: #787878; }
    .daterangepicker .calendar-table .next span,
    .daterangepicker .calendar-table .prev span {
      border-color: #adb5bd; }
  .daterangepicker td.in-range {
    background-color: #edeff1;
    color: #495057; }
  .daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #fff;
    color: #787878;
    opacity: 0.5; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #05f;
    color: #fff; }
  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: #05f;
    color: #fff; }
  .daterangepicker:after {
    border-bottom: 6px solid #fff; }
  .daterangepicker:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker .ranges li.active {
    background-color: #05f; }
  .daterangepicker .ranges li:hover {
    background-color: #f3f4f7; }
  .daterangepicker .drp-buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker select.ampmselect, .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect {
    background: white;
    border: 1px solid white;
    color: #fff; }
  .daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid rgba(0, 0, 0, 0.15); }

.leaflet-container {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057; }
  .leaflet-container a.leaflet-popup-close-button {
    color: #fff;
    font-size: 1.5rem;
    margin: 7px; }

.custom-map-marker-popup .leaflet-popup-content-wrapper {
  padding: 0px !important; }
  .custom-map-marker-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    margin: 0px !important; }
    .custom-map-marker-popup .leaflet-popup-content-wrapper .leaflet-popup-content p {
      margin: inherit; }

.custom-map-marker {
  font-size: 0.75rem;
  font-weight: 600;
  background-color: #fff;
  color: #495057;
  border-color: #fff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.275); }
  .custom-map-marker:before {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.275); }
  .custom-map-marker.active {
    background-color: #05f;
    border-color: #05f;
    color: #fff; }
    .custom-map-marker.active:before {
      border-top-color: #05f; }

.jarallax {
  position: relative;
  z-index: 0; }

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

@media (min-width: 992px) {
  .sidenav {
    position: fixed;
    top: 7rem;
    height: calc(100vh - 7rem);
    overflow: auto; } }

@media (min-width: 992px) {
  .sidenav-left {
    left: 0; } }

@media (min-width: 992px) {
  .sidenav-right {
    right: 0; } }

.footer .cta-content {
  position: relative;
  top: -90px; }

.footer .footer-content {
  position: relative;
  z-index: 1; }

.footer .footer-social-icon .footer-icon-bg {
  background: rgba(255, 255, 255, 0.08);
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s; }
  .footer .footer-social-icon .footer-icon-bg .footer-icon {
    height: 18px;
    width: 18px;
    stroke-width: 1.5px;
    color: #fff; }
  .footer .footer-social-icon .footer-icon-bg:hover {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.1); }

.footer .footer-submenu li {
  margin: 14px 0; }
  .footer .footer-submenu li .footer-item {
    color: #979797;
    font-size: 14px;
    transition: all 0.3s ease 0s; }
    .footer .footer-submenu li .footer-item:hover {
      color: #05f;
      padding-left: 6px; }

.footer .footer-bg-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.03; }

.icon {
  position: relative;
  width: 3rem;
  height: 3rem; }
  .icon.icon-with-bg {
    display: flex;
    align-items: center;
    justify-content: center; }
  .icon > svg {
    width: 3rem;
    height: 3rem; }

.icon-xxs.feather {
  width: 1rem;
  height: 1rem; }

.icon-xxs > svg {
  width: 1.25rem;
  height: 1.25rem; }

.icon-xs.feather {
  width: 1.2rem;
  height: 1.2rem; }

.icon-xs > svg {
  width: 1.5rem;
  height: 1.5rem; }

.icon-sm.feather {
  width: 1.8rem;
  height: 1.8rem; }

.icon-sm > svg {
  width: 2.25rem;
  height: 2.25rem; }

.icon-md.feather {
  width: 2.4rem;
  height: 2.4rem; }

.icon-md > svg {
  width: 3rem;
  height: 3rem; }

.icon-lg.feather {
  width: 3.2rem;
  height: 3.2rem; }

.icon-lg > svg {
  width: 4rem;
  height: 4rem; }

.icon-xl.feather {
  width: 4rem;
  height: 4rem; }

.icon-xl > svg {
  width: 5rem;
  height: 5rem; }

.icon-xxl.feather {
  width: 4.8rem;
  height: 4.8rem; }

.icon-xxl > svg {
  width: 6rem;
  height: 6rem; }

.icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important; }

.icon-dual {
  color: #787878;
  fill: rgba(120, 120, 120, 0.12); }

.icon-dual-primary {
  color: #05f;
  fill: rgba(0, 85, 255, 0.16); }
  .icon-dual-primary > svg {
    color: #05f;
    fill: rgba(0, 85, 255, 0.16); }

.icon-fill-primary {
  fill: #05f; }
  .icon-fill-primary > svg {
    fill: #05f; }

.icon-dual-secondary {
  color: #495057;
  fill: rgba(73, 80, 87, 0.16); }
  .icon-dual-secondary > svg {
    color: #495057;
    fill: rgba(73, 80, 87, 0.16); }

.icon-fill-secondary {
  fill: #495057; }
  .icon-fill-secondary > svg {
    fill: #495057; }

.icon-dual-success {
  color: #3cd278;
  fill: rgba(60, 210, 120, 0.16); }
  .icon-dual-success > svg {
    color: #3cd278;
    fill: rgba(60, 210, 120, 0.16); }

.icon-fill-success {
  fill: #3cd278; }
  .icon-fill-success > svg {
    fill: #3cd278; }

.icon-dual-info {
  color: #17a2b8;
  fill: rgba(23, 162, 184, 0.16); }
  .icon-dual-info > svg {
    color: #17a2b8;
    fill: rgba(23, 162, 184, 0.16); }

.icon-fill-info {
  fill: #17a2b8; }
  .icon-fill-info > svg {
    fill: #17a2b8; }

.icon-dual-warning {
  color: #ffc837;
  fill: rgba(255, 200, 55, 0.16); }
  .icon-dual-warning > svg {
    color: #ffc837;
    fill: rgba(255, 200, 55, 0.16); }

.icon-fill-warning {
  fill: #ffc837; }
  .icon-fill-warning > svg {
    fill: #ffc837; }

.icon-dual-danger {
  color: #f0323c;
  fill: rgba(240, 50, 60, 0.16); }
  .icon-dual-danger > svg {
    color: #f0323c;
    fill: rgba(240, 50, 60, 0.16); }

.icon-fill-danger {
  fill: #f0323c; }
  .icon-fill-danger > svg {
    fill: #f0323c; }

.icon-dual-light {
  color: #f4f5f7;
  fill: rgba(244, 245, 247, 0.16); }
  .icon-dual-light > svg {
    color: #f4f5f7;
    fill: rgba(244, 245, 247, 0.16); }

.icon-fill-light {
  fill: #f4f5f7; }
  .icon-fill-light > svg {
    fill: #f4f5f7; }

.icon-dual-dark {
  color: #343a40;
  fill: rgba(52, 58, 64, 0.16); }
  .icon-dual-dark > svg {
    color: #343a40;
    fill: rgba(52, 58, 64, 0.16); }

.icon-fill-dark {
  fill: #343a40; }
  .icon-fill-dark > svg {
    fill: #343a40; }

.icon-dual-orange {
  color: #ff784b;
  fill: rgba(255, 120, 75, 0.16); }
  .icon-dual-orange > svg {
    color: #ff784b;
    fill: rgba(255, 120, 75, 0.16); }

.icon-fill-orange {
  fill: #ff784b; }
  .icon-fill-orange > svg {
    fill: #ff784b; }

.icon-dual-teal {
  color: #20c997;
  fill: rgba(32, 201, 151, 0.16); }
  .icon-dual-teal > svg {
    color: #20c997;
    fill: rgba(32, 201, 151, 0.16); }

.icon-fill-teal {
  fill: #20c997; }
  .icon-fill-teal > svg {
    fill: #20c997; }

.icon-dual-gray-50 {
  color: #fefefe;
  fill: rgba(254, 254, 254, 0.16); }
  .icon-dual-gray-50 > svg {
    color: #fefefe;
    fill: rgba(254, 254, 254, 0.16); }

.icon-fill-gray-50 {
  fill: #fefefe; }
  .icon-fill-gray-50 > svg {
    fill: #fefefe; }

.icon-left-arrow,
.icon-right-arrow {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center; }
  .icon-left-arrow.icon-xxs,
  .icon-right-arrow.icon-xxs {
    width: 1.25rem;
    height: 1.25rem; }
  .icon-left-arrow.icon-xs,
  .icon-right-arrow.icon-xs {
    width: 1.5rem;
    height: 1.5rem; }
  .icon-left-arrow.icon-sm,
  .icon-right-arrow.icon-sm {
    width: 2.25rem;
    height: 2.25rem; }
  .icon-left-arrow.icon-md,
  .icon-right-arrow.icon-md {
    width: 3rem;
    height: 3rem; }
  .icon-left-arrow.icon-lg,
  .icon-right-arrow.icon-lg {
    width: 4rem;
    height: 4rem; }
  .icon-left-arrow.icon-xl,
  .icon-right-arrow.icon-xl {
    width: 5rem;
    height: 5rem; }
  .icon-left-arrow.icon-xxl,
  .icon-right-arrow.icon-xxl {
    width: 6rem;
    height: 6rem; }

.icon-left-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-arrow-left' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z'/%3E%3Cpath fill-rule='evenodd' d='M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E"); }

.icon-right-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-arrow-right' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z'/%3E%3Cpath fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8z'/%3E%3C/svg%3E"); }

.avatar-xs {
  height: 2rem;
  width: 2rem; }

.avatar-sm {
  height: 3rem;
  width: 3rem; }

.avatar-md {
  height: 4.5rem;
  width: 4.5rem; }

.avatar-lg {
  height: 6rem;
  width: 6rem; }

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem; }

.avatar-title {
  align-items: center;
  background-color: #05f;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%; }

.avatar-group {
  padding-left: 12px; }
  .avatar-group .avatar-group-item {
    margin: 0 0 10px -12px;
    display: inline-block; }

.avatar-border {
  box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06) !important;
  border: 2px solid #fff !important; }

.ribbon {
  position: absolute;
  padding-left: 0.5rem;
  padding-right: 1.4rem;
  clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 0 50%, 0 0);
  top: 1rem;
  left: 0px;
  color: #fff;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  font-weight: 600;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.ribbon-primary {
  background: #05f; }
  .ribbon-primary:before {
    border-color: #004de6; }

.ribbon-secondary {
  background: #495057; }
  .ribbon-secondary:before {
    border-color: #3d4349; }

.ribbon-success {
  background: #3cd278; }
  .ribbon-success:before {
    border-color: #2ec76b; }

.ribbon-info {
  background: #17a2b8; }
  .ribbon-info:before {
    border-color: #148ea1; }

.ribbon-warning {
  background: #ffc837; }
  .ribbon-warning:before {
    border-color: #ffc11e; }

.ribbon-danger {
  background: #f0323c; }
  .ribbon-danger:before {
    border-color: #ee1a25; }

.ribbon-light {
  background: #f4f5f7; }
  .ribbon-light:before {
    border-color: #e5e8ec; }

.ribbon-dark {
  background: #343a40; }
  .ribbon-dark:before {
    border-color: #292d32; }

.ribbon-orange {
  background: #ff784b; }
  .ribbon-orange:before {
    border-color: #ff6532; }

.ribbon-teal {
  background: #20c997; }
  .ribbon-teal:before {
    border-color: #1cb386; }

.ribbon-gray-50 {
  background: #fefefe; }
  .ribbon-gray-50:before {
    border-color: #eff1f4; }

.ribbon2 {
  padding: 2px 60px;
  transform: rotate(45deg);
  position: absolute;
  top: 16px;
  right: -56px; }

.ribbon2-primary {
  background: #05f; }
  .ribbon2-primary:before {
    border-color: #004de6; }

.ribbon2-secondary {
  background: #495057; }
  .ribbon2-secondary:before {
    border-color: #3d4349; }

.ribbon2-success {
  background: #3cd278; }
  .ribbon2-success:before {
    border-color: #2ec76b; }

.ribbon2-info {
  background: #17a2b8; }
  .ribbon2-info:before {
    border-color: #148ea1; }

.ribbon2-warning {
  background: #ffc837; }
  .ribbon2-warning:before {
    border-color: #ffc11e; }

.ribbon2-danger {
  background: #f0323c; }
  .ribbon2-danger:before {
    border-color: #ee1a25; }

.ribbon2-light {
  background: #f4f5f7; }
  .ribbon2-light:before {
    border-color: #e5e8ec; }

.ribbon2-dark {
  background: #343a40; }
  .ribbon2-dark:before {
    border-color: #292d32; }

.ribbon2-orange {
  background: #ff784b; }
  .ribbon2-orange:before {
    border-color: #ff6532; }

.ribbon2-teal {
  background: #20c997; }
  .ribbon2-teal:before {
    border-color: #1cb386; }

.ribbon2-gray-50 {
  background: #fefefe; }
  .ribbon2-gray-50:before {
    border-color: #eff1f4; }

.header-1 {
  position: relative; }
  .header-1::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(0, 85, 255, 0.07) 0%, rgba(0, 85, 255, 0.05) 100%) no-repeat left center;
    background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
    transform: skewY(-6deg);
    transform-origin: top left; }
  @media (max-width: 768px) {
    .header-1::before {
      background-size: 100% 100%; } }

.header-2 {
  position: relative; }
  .header-2::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(white 0%, rgba(255, 200, 55, 0.05) 100%) no-repeat left center;
    background-size: calc(1240px + (100vw - 1240px)/ 2) 100%;
    transform: skewY(3deg);
    transform-origin: top left; }
  .header-2.primary {
    padding-bottom: 5rem; }
    .header-2.primary::before {
      top: -6rem;
      background: linear-gradient(#f4f5f7 0%, #fafafb 100%) no-repeat left center;
      background-size: calc(1240px + (100vw - 1240px)/ 2) 100%; }
  @media (max-width: 768px) {
    .header-2::before {
      background-size: 100% 100%; } }

.header-3 {
  position: relative; }
  .header-3::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(0, 85, 255, 0.07) 0%, rgba(0, 85, 255, 0.05) 100%) no-repeat right center; }
  @media (max-width: 768px) {
    .header-3::before {
      background-size: 100% 100%; } }

.header-4 {
  position: relative; }
  .header-4::before {
    content: "";
    width: calc(100% - 60px);
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(255, 200, 55, 0.05) 0%, rgba(255, 200, 55, 0.07) 100%) no-repeat left center; }

.header-5 {
  position: relative; }
  .header-5::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(255, 120, 75, 0.05) 0%, rgba(255, 120, 75, 0.07) 100%) no-repeat left center; }
  @media (max-width: 768px) {
    .header-5::before {
      background-size: 100% 100%; } }

.header-6 {
  position: relative; }
  .header-6::before {
    content: "";
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(255, 120, 75, 0.075) 0%, rgba(255, 200, 55, 0.1) 100%) no-repeat left center; }

.header-7 {
  position: relative; }
  .header-7::before {
    content: "";
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    background: linear-gradient(rgba(244, 245, 247, 0.25) 0%, rgba(244, 245, 247, 0.4) 100%) no-repeat right center; }
  .header-7 .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(33, 37, 41, 0.25); }

.hero-title {
  font-size: 3rem;
  line-height: 1.3;
  font-weight: 600; }
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2.6rem;
      line-height: 1.1; } }
  @media (max-width: 576px) {
    .hero-title {
      font-size: 2rem !important; } }

.hero-1 {
  position: relative; }
  .hero-1 .slider {
    position: absolute;
    width: 320px;
    height: 240px;
    z-index: 10;
    bottom: 1rem;
    left: -15rem; }
    .hero-1 .slider .swiper-container {
      z-index: 100 !important;
      height: 100%;
      width: 100%; }
      .hero-1 .slider .swiper-container .swiper-slide .swiper-slide-content {
        max-width: 280px;
        text-align: left;
        padding-top: 2rem;
        position: relative; }
  .hero-1 .hero-container {
    position: relative; }
    .hero-1 .hero-container .hero-img {
      position: relative; }
      .hero-1 .hero-container .hero-img img {
        position: relative;
        z-index: 2; }
      .hero-1 .hero-container .hero-img::before {
        content: "";
        background: url("/assets/images/pattern/dot3.svg");
        width: 6rem;
        height: 6rem;
        position: absolute;
        bottom: 4rem;
        left: 0rem;
        z-index: 0; }
      .hero-1 .hero-container .hero-img::after {
        content: "";
        background: url("/assets/images/pattern/dot3.svg");
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 0rem;
        right: 0rem;
        z-index: 0; }
    .hero-1 .hero-container .quote {
      position: relative; }
      .hero-1 .hero-container .quote::before {
        font-size: 6rem;
        line-height: 0rem;
        color: rgba(240, 50, 60, 0.2);
        z-index: 0;
        content: "\201D";
        position: absolute;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        position: absolute;
        bottom: 0;
        right: 0; }
  .hero-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 22%;
    z-index: -2;
    background: linear-gradient(#f4f5f7 0%, #fafafb 100%); }
  @media (max-width: 768px) {
    .hero-1 .slider {
      display: none; }
    .hero-1 .hero-container::before, .hero-1 .hero-container::after {
      display: none; }
    .hero-1 .hero-container .action-buttons {
      justify-content: center !important; } }

.hero-2 {
  height: 700px; }
  @media (max-width: 1024px) {
    .hero-2 {
      height: 700px; } }
  @media (min-width: 769px) and (max-width: 1024px) {
    .hero-2 {
      height: 48vh; } }
  .hero-2 .slider {
    position: relative; }
    .hero-2 .slider .form-container {
      position: absolute;
      top: 0rem;
      width: 100%;
      height: auto;
      z-index: 2;
      left: 0;
      right: 0; }
    .hero-2 .slider .navigations .swiper-custom-prev,
    .hero-2 .slider .navigations .swiper-custom-next {
      padding: .15rem .3rem;
      line-height: 1.25;
      border-radius: 0.15rem; }
    .hero-2 .slider .slider-item {
      position: relative;
      height: 520px;
      background-size: cover; }

@media (max-width: 768px) {
  .hero-2 .slider {
    position: relative; }
    .hero-2 .slider .form-container {
      position: absolute;
      top: -1rem;
      width: 100%;
      height: auto;
      z-index: 2;
      left: 0;
      right: 0; }
    .hero-2 .slider .navigations .swiper-custom-prev,
    .hero-2 .slider .navigations .swiper-custom-next {
      padding: .15rem .3rem;
      line-height: 1.25;
      border-radius: 0.15rem; }
    .hero-2 .slider .slider-item {
      position: relative;
      height: 240px;
      background-size: cover; } }

@media (max-width: 1024px) {
  .hero-2 {
    height: auto;
    padding: 50px 0 0; } }

@keyframes rotating {
  0% {
    transform: rotate(0deg);
    top: 10%;
    left: 5%; }
  100% {
    transform: rotate(360deg);
    top: 60%;
    left: 15%; } }

@keyframes rotating2 {
  0% {
    transform: rotate(0deg);
    bottom: 10%;
    right: 10%; }
  100% {
    transform: rotate(360deg);
    bottom: 80%;
    right: 30%; } }

@keyframes rotating3 {
  0% {
    transform: rotate(0deg);
    bottom: 0%;
    right: 65%; }
  100% {
    transform: rotate(360deg);
    bottom: 50%;
    right: 35%; } }

.hero-with-shapes .shape1 {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating alternate ease-in-out infinite 6s; }

.hero-with-shapes .shape2 {
  position: absolute;
  bottom: 20%;
  right: 10%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating2 alternate ease-in-out infinite 6s; }

.hero-with-shapes .shape3 {
  position: absolute;
  bottom: 0%;
  right: 65%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg");
  animation: rotating3 alternate ease-in-out infinite 6s; }

.hero-3 .shape1 {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square.svg"); }

.hero-3 .shape2 {
  position: absolute;
  top: 40%;
  right: 10%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg"); }

.hero-3 .shape3 {
  position: absolute;
  top: 5%;
  right: 50%;
  width: 7rem;
  height: 7rem;
  background: url("/assets/images/shapes/rounded-square2.svg"); }

.hero-3 .img-container {
  position: relative; }
  .hero-3 .img-container img {
    position: relative;
    z-index: 1; }

@media (max-width: 768px) {
  .hero-3 .img-container {
    margin-top: 1rem; }
    .hero-3 .img-container::before {
      left: 0;
      top: 0;
      height: 100%; } }

.hero-5 {
  height: 65vh; }
  .hero-5 .links-social {
    position: absolute;
    top: 10%;
    right: 0px;
    writing-mode: vertical-rl;
    text-orientation: mixed; }

@media (max-width: 576px) {
  .hero-5 .shape.bottom {
    bottom: -5px; } }

@media (max-width: 1024px) {
  .hero-5 {
    height: auto; } }

.hero-6 {
  position: relative; }
  .hero-6::before {
    content: "";
    position: absolute;
    top: -12rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    background: linear-gradient(rgba(255, 120, 75, 0.02) 0, rgba(255, 120, 75, 0.03) 100%);
    transform: skewY(3deg);
    transform-origin: top left; }
  .hero-6 .shapes {
    position: relative;
    height: 100%; }
    .hero-6 .shapes .shape1 {
      position: absolute;
      bottom: 1%;
      left: 2%;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      border: 5px solid rgba(240, 50, 60, 0.3);
      background: transparent; }
    .hero-6 .shapes .shape2 {
      position: absolute;
      top: 50%;
      right: 20%;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      border: 5px solid rgba(255, 200, 55, 0.3);
      background: transparent; }
  .hero-6 .video-container .video-item {
    padding: 1rem 0 1rem 1rem;
    position: relative;
    width: 100%; }
    .hero-6 .video-container .video-item img {
      position: relative;
      z-index: 2;
      border-top-right-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem; }
    .hero-6 .video-container .video-item .video-overlay {
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 3; }
    .hero-6 .video-container .video-item::before {
      position: absolute;
      width: calc(100% + -3rem);
      height: calc(100% + -2rem);
      top: 2rem;
      content: "";
      border: 2px dashed #ff784b;
      z-index: 1;
      left: 0rem;
      border-bottom-left-radius: 2.15rem;
      border-top-right-radius: 2.15rem; }

@media (max-width: 768px) {
  .hero-6 .hero-sub-title {
    font-size: 14px !important;
    text-align: left; }
  .hero-6 .video-container {
    width: 100%; }
    .hero-6 .video-container .video-item {
      width: 100%;
      padding: 1rem 0 1rem 0rem; }
      .hero-6 .video-container .video-item::before {
        display: none; } }

.hero-7 .hero-right .img-container {
  position: relative;
  padding: 0.6rem;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
  border-radius: 0.5rem;
  min-width: 150%; }
  .hero-7 .hero-right .img-container img {
    max-width: 100%;
    position: relative;
    z-index: 1; }

.hero-7 .hero-right::before {
  content: "";
  background: url("/assets/images/pattern/dot2.svg");
  width: 8rem;
  height: 8rem;
  position: absolute;
  bottom: -2.25rem;
  left: -2.25rem;
  z-index: 0; }

@media (max-width: 1024px) {
  .hero-7 .hero-right .img-container {
    min-width: 100%; } }

.hero-9 {
  position: relative; }
  .hero-9 .hero-top {
    position: relative;
    text-align: center;
    overflow: hidden; }
    .hero-9 .hero-top::after {
      content: "";
      background: url("/assets/images/pattern/dot.svg");
      position: absolute;
      width: 4rem;
      height: 4rem;
      right: -1rem;
      bottom: -1rem;
      transform: rotate(45deg); }
  .hero-9 .hero-cta {
    text-align: center;
    padding: 0;
    position: absolute;
    display: block;
    transform: translate3d(0, -50%, 0);
    left: 1rem;
    right: 1rem;
    margin: auto;
    z-index: 5; }
    .hero-9 .hero-cta .btn-cta {
      width: 18%;
      text-transform: uppercase;
      font-weight: 600; }
  .hero-9 .hero-bottom {
    position: relative; }
    .hero-9 .hero-bottom .hero-image {
      height: 560px; }

@media (max-width: 768px) {
  .hero-9 .hero-cta .btn-cta {
    width: 75%; }
  .hero-9 .hero-bottom .hero-image {
    height: 260px; } }

.hero-10 .img-container {
  position: relative;
  min-width: 140%;
  margin-left: -40%; }
  .hero-10 .img-container img {
    z-index: 1;
    position: relative; }

@media (max-width: 768px) {
  .hero-10 .img-container {
    min-width: 100%;
    margin-left: 0; } }

.hero-11 {
  position: relative; }
  .hero-11::before {
    background: linear-gradient(#f3f4f7 0%, #f4f5f7 100%) no-repeat left center;
    position: absolute;
    top: 0;
    bottom: 40%;
    left: 0;
    right: 0;
    content: ''; }
  .hero-11 .hero-content {
    position: relative; }
  .hero-11 .slider-container {
    position: relative; }
    .hero-11 .slider-container .slider-container-body {
      position: relative;
      padding: 0.75rem;
      z-index: 1; }
    .hero-11 .slider-container::after {
      content: "";
      background: url("/assets/images/pattern/dot5.svg");
      width: 6rem;
      height: 6rem;
      position: absolute;
      top: -2.5rem;
      right: 4rem;
      z-index: 0; }
  .hero-11 .swiper-slide-content {
    position: relative; }
    .hero-11 .swiper-slide-content .video-overlay {
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 10; }

.hero-12 {
  height: 640px; }
  .hero-12 .hero-image {
    position: relative; }
    .hero-12 .hero-image img {
      position: relative;
      z-index: 1; }
    .hero-12 .hero-image::before, .hero-12 .hero-image::after {
      content: "";
      background: url("/assets/images/pattern/dot3.svg");
      width: 8rem;
      height: 8rem;
      position: absolute;
      z-index: 0; }
    .hero-12 .hero-image::before {
      bottom: 2rem; }
      @media (max-width: 768px) {
        .hero-12 .hero-image::before {
          top: 6rem; } }
    .hero-12 .hero-image::after {
      top: -2rem;
      right: 8rem; }
  @media (max-width: 768px) {
    .hero-12 .hero-image::after {
      display: none; } }

.hero-13 .hero-right .slider-container {
  position: relative; }
  .hero-13 .hero-right .slider-container .slider-container-body {
    position: relative;
    padding: 0.5rem;
    z-index: 1; }
  .hero-13 .hero-right .slider-container::after {
    content: "";
    background: url("/assets/images/pattern/dot3.svg");
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: -3rem;
    right: 3rem;
    z-index: 0; }

.hero-13 .hero-right .swiper-slide-content {
  position: relative; }
  .hero-13 .hero-right .swiper-slide-content .video-overlay {
    background: rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10; }

.hero-13 .hero-right .img-container {
  position: relative;
  padding: 0.3rem;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08);
  border-radius: 0.5rem;
  min-width: 160%; }

.hero-13 .hero-right::before {
  content: "";
  background: url("/assets/images/pattern/dot2.svg");
  width: 6rem;
  height: 6rem;
  position: absolute;
  bottom: -2rem;
  left: -2rem;
  z-index: 0; }

.hero-13 .hero-right::after {
  content: "";
  background: url("/assets/images/pattern/dot5.svg");
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: -2.5rem;
  right: -6rem;
  z-index: 0;
  transform: rotate(45deg);
  z-index: 0; }

@media (max-width: 1024px) {
  .hero-13 .hero-right .img-container {
    min-width: 100%; }
  .hero-13 .hero-right::after {
    right: 2rem; } }

.features-1 .img-content {
  position: relative; }
  .features-1 .img-content::before {
    content: "";
    background: linear-gradient(rgba(255, 120, 75, 0.1) 0, rgba(255, 120, 75, 0.15) 100%);
    width: 65%;
    height: 60%;
    position: absolute;
    bottom: 3rem;
    right: 5rem;
    z-index: -1;
    border-radius: 3rem;
    transform: rotate(20deg); }
  .features-1 .img-content::after {
    content: "";
    background: url("/assets/images/pattern/dot4.svg");
    width: 100%;
    height: 100%;
    position: absolute;
    top: -3rem;
    right: 3rem;
    z-index: -1; }
  .features-1 .img-content .img-up {
    max-width: 80%; }

@media (max-width: 768px) {
  .features-1 .img-content .img-up {
    max-width: 90%; } }

.features-2 .shapes {
  position: relative; }
  .features-2 .shapes .shape1 {
    position: absolute;
    top: 30%;
    right: 3%;
    background: #3377ff;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 100%; }
  .features-2 .shapes .shape2 {
    position: absolute;
    bottom: 20%;
    left: 3%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10.4px 6px 0;
    border-color: transparent #65dc95 transparent transparent; }
  .features-2 .shapes .shape3 {
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10.4px 6px 0;
    border-color: transparent #f36269 transparent transparent; }
  .features-2 .shapes .shape4 {
    position: absolute;
    top: 35%;
    right: 30%;
    background: #ffd66a;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 100%; }
  .features-2 .shapes .shape5 {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10.4px;
    border-color: transparent transparent transparent #ffd66a; }
  .features-2 .shapes .shape6 {
    position: absolute;
    bottom: 40%;
    left: 5%;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0);
    border-radius: 100%;
    border: calc(12px/8) solid #6699ff;
    box-sizing: content-box; }

.features-3 .img-content {
  position: relative; }
  .features-3 .img-content::before {
    content: "";
    background: url("/assets/images/pattern/dot5.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: -2rem;
    left: -2rem;
    z-index: -1; }
  .features-3 .img-content::after {
    content: "";
    background: url("/assets/images/pattern/dot2.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: -2rem;
    right: -2rem;
    z-index: -1; }

.features-3 .img-content2 {
  position: relative; }
  .features-3 .img-content2::before {
    content: "";
    background: url("/assets/images/pattern/dot5.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: -2rem;
    right: -2rem;
    z-index: -1; }
  .features-3 .img-content2::after {
    content: "";
    background: url("/assets/images/pattern/dot2.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: -2rem;
    left: -2rem;
    z-index: -1; }

@media (max-width: 768px) {
  .features-3 .img-content .img-up,
  .features-3 .img-content2 .img-up {
    margin: 0 auto;
    max-width: 90%; }
  .features-3 .img-content::after {
    right: 0rem; }
  .features-3 .img-content::before {
    left: 0rem; }
  .features-3 .img-content2::after {
    left: 0rem; }
  .features-3 .img-content2::before {
    right: 0rem; } }

.features-4 .img-content img {
  border: 10px solid #fff; }

.features-4 .img-content:after {
  content: "";
  background: linear-gradient(rgba(60, 210, 120, 0.1) 0, rgba(60, 210, 120, 0.15) 100%);
  position: absolute;
  border-radius: 70% 30% 76% 24% / 68% 30% 73% 22%;
  top: -20px;
  bottom: -20px;
  height: 120%;
  width: 70%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1; }

.features-4 .feature-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px; }

@media (max-width: 768px) {
  .features-4 .feature-content {
    position: relative;
    top: 0;
    transform: none;
    left: 0; } }

.features-5 .feature-item:hover {
  transition: box-shadow 0.25s ease-in-out 0.15s;
  box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08) !important; }

.features-6 .feature-item {
  transition: all 0.3s ease-in-out;
  position: relative; }
  .features-6 .feature-item .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0; }
  .features-6 .feature-item .btn-view {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); }
  .features-6 .feature-item:hover {
    box-shadow: 0 7px 14px 0 rgba(52, 58, 64, 0.06) !important; }
    .features-6 .feature-item:hover .overlay {
      opacity: 1; }

.features-7 .feature-item .number {
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.3; }

.features-8 .feature-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 400px;
  z-index: 1; }
  .features-8 .feature-content .feature-text {
    position: relative;
    z-index: 1; }
  .features-8 .feature-content::after {
    content: "";
    background: url("/assets/images/pattern/dot2.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: -2rem;
    left: -2rem;
    z-index: -1; }

.features-8 .feature-img .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.25);
  z-index: 0; }

.features-8 .feature-img::before {
  content: "";
  background: url("/assets/images/pattern/dot5.svg");
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -2rem;
  right: -2rem;
  z-index: -1; }

.features-8 .feature-img img {
  position: relative;
  z-index: -1;
  width: 85%; }

@media (max-width: 576px) {
  .features-8 .feature-content {
    position: relative;
    top: 0;
    transform: none;
    max-width: 100%; }
    .features-8 .feature-content::after {
      left: -0.5rem; }
  .features-8 .feature-img img {
    width: 100%; }
  .features-8 .feature-img::before {
    right: -0.5rem; } }

.features-9 .feature-item {
  transition: all 0.25s ease-in-out 0.15s; }
  .features-9 .feature-item .icon {
    border-bottom-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
    transition: all 0.25s ease-in-out 0.15s; }
  .features-9 .feature-item:hover {
    box-shadow: 0 0.8rem 1.2rem rgba(52, 58, 64, 0.08) !important; }
    .features-9 .feature-item:hover .icon {
      border-top-left-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 0 !important; }

.testimonials-1 .slider {
  position: relative; }
  .testimonials-1 .slider::before {
    content: "";
    background: url("/assets/images/pattern/dot3.svg");
    width: 6rem;
    height: 6rem;
    position: absolute;
    bottom: -2.5rem;
    left: -2.5rem;
    z-index: 0; }
  .testimonials-1 .slider::after {
    content: "";
    background: url("/assets/images/pattern/dot3.svg");
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
    z-index: 0;
    transform: rotate(90deg); }

@media (max-width: 768px) {
  .testimonials-1 .slider::after {
    display: none; } }

.testimonials-2 .slider,
.testimonials-3 .slider {
  position: relative; }
  .testimonials-2 .slider .swiper-slide .img-author,
  .testimonials-3 .slider .swiper-slide .img-author {
    max-height: 300px; }
  .testimonials-2 .slider::before,
  .testimonials-3 .slider::before {
    content: "";
    background: url("/assets/images/pattern/dot5.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: -2.25rem;
    left: -2.25rem;
    z-index: 0; }
  .testimonials-2 .slider::after,
  .testimonials-3 .slider::after {
    content: "";
    background: url("/assets/images/pattern/dot5.svg");
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: -2.25rem;
    right: -2.25rem;
    z-index: 0; }
  .testimonials-2 .slider .testimonial-body,
  .testimonials-3 .slider .testimonial-body {
    padding: 2rem; }
  .testimonials-2 .slider .quotation-mark,
  .testimonials-3 .slider .quotation-mark {
    font-weight: 700;
    font-size: 6rem;
    font-family: "Times New Roman", Georgia, Serif;
    line-height: 0.7; }

@media (max-width: 768px) {
  .testimonials-2 .slider::after,
  .testimonials-3 .slider::after {
    display: none; } }

.testimonials-3 .slider::before, .testimonials-3 .slider::after {
  background: none; }

.testimonials-4 .client-img {
  background-size: cover;
  background-repeat: no-repeat; }

.testimonials-4 .quotation-mark {
  font-weight: 700;
  font-size: 8rem;
  font-family: "Times New Roman", Georgia, Serif;
  line-height: 0.7; }

.testimonials-4 .navigations {
  position: absolute;
  right: 10px;
  bottom: 25px;
  z-index: 1; }

.code-block {
  position: relative;
  border: 1px solid #dee2e6 !important;
  padding: 1rem 1rem;
  background: #fff;
  border-radius: 0.25rem;
  margin-bottom: 1rem; }
  .code-block h6, .code-block .h6 {
    margin-top: 0px; }
  .code-block .prettyprint {
    background: #f4f5f7;
    border: none;
    padding: 1rem 1rem;
    margin-bottom: 0px; }
  .code-block .btn-copy-clipboard {
    position: absolute;
    top: 10px;
    right: 10px; }

.doc-carousel .carousel-item {
  height: 400px; }

.doc-carousel .carousel-item img {
  height: 400px;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0; }

.filter-menu .filter-menu-item {
  display: inline-block;
  font-weight: 500;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-size: 0.82031rem;
  margin: 4px 2px;
  border-color: #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .filter-menu .filter-menu-item.active {
    color: #fff !important;
    background-color: #05f !important;
    border-color: #05f !important; }
  .filter-menu .filter-menu-item:focus, .filter-menu .filter-menu-item.focus {
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(0, 85, 255, 0.25); }
  .filter-menu .filter-menu-item.disabled, .filter-menu .filter-menu-item:disabled {
    opacity: 0.65; }
  .filter-menu .filter-menu-item:hover, .filter-menu .filter-menu-item:focus {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(60, 72, 88, 0.15), 0 2px 4px 2px rgba(60, 72, 88, 0.18); }

.masonry-grid {
  position: relative;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: height 0.3s ease-out, opacity 0.3s ease-out 0.3s; }
  .masonry-grid [data-masonry-el] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out; }
